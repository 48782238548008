import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Company } from './company.types';

const { CompanyUrl } = ApiEndpoints;

export const companyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyProfile: build.query<Company, void>({
      query: () => ({ method: 'GET', url: `/${CompanyUrl}/profile` }),
      providesTags: () => [{ type: TagType.Company, id: 'AuthenticatedCompany' }],
    }),
    updateCompanyProfile: build.mutation<Company, Company>({
      query: (company: Company) => ({ method: 'POST', url: `/${CompanyUrl}/profile`, data: company }),
      invalidatesTags: () => [{ type: TagType.Company, id: 'AuthenticatedCompany' }],
    }),
    updateCompanySettings: build.mutation<Company, Company>({
      query: (company: Company) => ({ method: 'POST', url: `/${CompanyUrl}/settings`, data: company }),
      invalidatesTags: () => [{ type: TagType.Company, id: 'AuthenticatedCompany' }],
    }),
    updateCompanyLogo: build.mutation<Company, FormData>({
      query: (logo: FormData) => ({
        method: 'POST',
        url: `/${CompanyUrl}/logo`,
        data: logo,
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
      invalidatesTags: () => [{ type: TagType.Company, id: 'AuthenticatedCompany' }],
    }),
  }),
});

export const {
  useGetCompanyProfileQuery,
  useUpdateCompanyProfileMutation,
  useUpdateCompanySettingsMutation,
  useUpdateCompanyLogoMutation,
} = companyApi;
