import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import splitViewStyles from '../SplitView/SplitView.module.scss';
import { InvoiceLoadingState } from './InvoiceLoadingState';
import { MasterViewLoader } from './MasterViewLoader';
import { MasterView } from '../SplitView/MasterView.tsx';
import { DetailView } from '../SplitView/DetailView.tsx';

export const SplitViewLoadingState = React.memo(() => {
  return (
    <Box className={isMobile ? splitViewStyles.containerMobile : splitViewStyles.container}>
      <MasterView>
        <MasterViewLoader />
      </MasterView>
      <DetailView>
        <InvoiceLoadingState />
      </DetailView>
    </Box>
  );
});
