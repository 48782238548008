import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

interface IDisclosure {
  type?: 'link' | 'button';
  label?: string;
}
const TermsConditions: React.FC = () => {
  return (
    <Box display="flex" padding="5" overflowY="auto" flexDirection="column" alignItems="center">
      <Box marginBottom="5">
        <Heading>Услови за користење</Heading>
        <Text fontSize="xs" className="text-muted">
          Објавено: 25 Септември 2018 | Ажурирано: 01 Февруари 2024
        </Text>
      </Box>
      <Box maxWidth={768}>
        <TermsConditionsBody />
      </Box>
    </Box>
  );
};

export default TermsConditions;

const TermsConditionsBody: React.FC = () => {
  return (
    <Flex direction="column" gap={4}>
      <Text>
        Со креирање на <strong>Brojki</strong> сметка, вие ги прифаќате следните услови
      </Text>
      <Text>
        Овие услови се однесуват на веб страницата brojki.com, app.brojki.com, brojki.mk, app.brojki.mk, вклучувајќи ги
        сите поддомени во сопственост и под раководство на ПСБ АРКИТЕКТС ДООЕЛ (колективно, “brojki”, "PSB Architects",
        "brojki.com", "brojki.mk", "brojki.com.mk", “ние”, “наше”, или “нам”).
      </Text>
      <Text>
        Со пристапување или користење на нашите услуги, вие прифаќате дека ги прочитавте и разбравте овие услови за
        користење и се согласувате со нив. Ако не се согласувате со условите, ве молиме не ги користете нашите услуги.
      </Text>
      <Text>
        ПСБ АРКИТЕКТС ДООЕЛ (“Brojki”, “Бројки” “ние”, “наше” , “нашите”, “нам”, “www.brojki.com”, “www.brojki.mk”, “PSB
        Architects”, “ПСБ АРКИТЕКТС” и други термини со слично значење) ја обезбедува оваа веб-странa (www.brojki.com) и
        сите услуги преку или од оваа веб-страна до Вас се предмет на овие услови за користење. Овие правила и услови за
        користење заедно со Политиката за приватност на Brojki (заеднички, „Услови“) го регулираат Вашето користење на
        оваа веб-страна и услугите што ги нуди.
      </Text>
      <Text>
        Во овие Услови, ние ја нарекуваме оваа веб-страна, која било веб-локација наследничка (заедно, „страна“ ,
        “страница”) и софтверот што го обезбедуваме „апликација“.
      </Text>
      <Text>
        Ве молиме внимателно прочитајте ги овие Услови пред да ги користите услугите. Со пристап или користење на
        Услугите, Вие потврдувате дека сте ги прочитале и разбрале овие Услови и се согласувате дека сте законски
        обврзани со овие Услови и сите политики и упатства инкорпорирани со референца во овие Услови. Ако не се
        согласувате да бидете обврзани со овие Услови во целост, не смеете да ги користите Услугите.
      </Text>
      <Text>
        Brojki има право, по сопствена дискреција, да ги измени, пормени или на друг начин да ги измени овие Услови, или
        која било политика или упатства што се применуваат на Услугите, во секое време. Ако ние го сториме тоа, ќе
        направиме разумни напори за да ви ги пренесеме овие промени, и ќе ве информираме преку e-мејл адресата што ја
        имате наведено во информациите за регистрација, доколку ги има, или ќе објавиме известување во самата
        апликацијата.
      </Text>
      <Text>
        Освен ако не е поинаку наведено, какви било промени или модификации ќе стапат на сила веднаш по објавувањето на
        ревидираните Услови на оваа страница, а Вашата континуирана употреба на Услугите после тоа време ќе претставува
        Ваш договор да бидете обврзани со таквите изменети Услови. Одвреме-навреме треба да ги прегледате Условите и
        сите политики и документи вградени во нив за да ги разберете условите и условите што се однесуваат на вашето
        користење на Услугите. Условите секогаш ќе го прикажуваат датумот „последно ажурирање“ на врвот. Ако не се
        согласувате со изменетите Услови, мора да престанете да ги користите Услугите. Можете да ја откажете Вашата
        сметка кај нас без понатамошна обврска, освен износот што се должи за состојбата на периодот на наплата во кој
        сте ја откажале сметката (ако вашиот период на наплата е месечен, ние ќе ја пропорционализираме вашата сметка до
        најблискиот месец по откажувањето ). Ако имате какви било прашања во врска со Условите, ве молиме пратете ни
        e-мејл или контактирајте не на info@brojki.com.
      </Text>
      <Text>
        Во овие Услови, нашите клиенти и лицата на кои им даваат „кадар“ или „советник“ пристап до нивната сметка на
        Brojki се нарекуваат „претплатници“, а клиентите на нашите клиенти кои ги користат услугите (на пример, за
        прегледување или плаќање на претплатниците и фактурите) се нарекуваат „клиенти“. Во овие Услови, корисниците на
        Услугите, без разлика дали се претплатници, клиенти, членови на форумот на Brojki, луѓе кои објавуваат коментари
        на блогот на Brojki или обични прелистувачи на страницата, се нарекуваат „корисници“.
      </Text>
      <Text>
        Услугите се само за Ваша сопствена употреба. Не смеете да ги препродавате, изнајмувате или да ги давате на друг
        начин на некој друг, освен како што е изрично дозволено преку апликацијата.
      </Text>
      <Text>
        Услугите не се наменети да ги користат деца. Мора да имате најмалку осумнаесет (18) години за да ги користите
        Услугите. Ако утврдиме дека корисникот е на возраст под осумнаесет (18), ќе ја прекинеме употребата на услугите
        од тој корисник.
      </Text>
      <Heading size="md">1. Политика за Приватност</Heading>
      <Text>
        Ве молиме погледнете ја политиката за приватност на Brojki, достапна на („Политика за приватност“) за информации
        за тоа како Brojki собира, користи и открива лични информации за идентификација од своите корисници. Со
        користење на Услугите, Вие се согласувате со нашата употреба, собирање и откривање на информации за лична
        идентификација во согласност со Политиката за приватност.
      </Text>
      {/* <Text>
        Овие Услови за услуги го вклучуваат Договорот за обработка на податоци („DPA“), кога GDPR се применува на вашата
        употреба на услугите на Brojki за обработка на податоците за клиентите (како што е дефинирано во DPA). го
        заменува секој претходно договорен додаток за обработка на податоци помеѓу вас и Brojki во врска со Регулативата
        (ЕУ) 2016/679 и/или Директивата 95/46/ЕЗ.
      </Text> */}
      <Text>
        Апликацијата Brojki може да содржи технологии кои следат, снимаат и известуваат за Brojki во врска со
        користењето на aпликацијата, вклучувајќи, но не ограничувајќи се на информации за уредите што се користат за
        пристап до aпликацијата; и зачестеноста, видот и начинот на користење на Brojki (колективно, „Податоци за
        користење“). Се согласувате дека Brojki може, по своја дискреција, да собира и користи податоци за користење за
        поддршка, одржување и подобрување на Brojki и за спроведување на правата на Brojki според Условите. Доколку било
        кој податок за користење е лична информација во смисла на важечкиот закон, вие со ова: (а) се согласувате за
        собирање, користење и откривање на таквите податоци за користење од страна на Brojki за овие цели; и (б) да
        претставувате и гарантирате дека сте добиле согласност од кое било поединец на кое таквите податоци за користење
        се однесуваат на собирањето, употребата и откривањето на таквите податоци за користење од страна на Brojki за
        овие цели, или дека согласноста од таквите поединци не е потребна за да се добијат според важечкиот закон.
      </Text>
      <Heading size="md">2. Безбедност на сметката</Heading>
      <Text>
        Ако се регистрирате за сметка на Услугите, се согласувате да:(а) да обезбедите вистинити, точни, тековни и
        целосни информации како што може да се побара од било кои формулари за регистрација на Услугите („Податоци за
        регистрација“); и (б) навремено ажурирајте ги податоците за регистрација и сите други информации што ги давате
        на Brojki, за да се осигуруват дека тие остануваат вистинити, точни и целосни.
      </Text>
      <Text>
        Се согласувате да ги чувате Вашите акредитиви за пристап (како лозинки, или други информации потребни за пристап
        до Услугата) безбедни и доверливи. Мора веднаш да го известите Brojki за каква било неовластена употреба (или
        сомнителна неовластена употреба) на Вашите акредитиви за пристап. Вие сте одговорни за сите активности на Вашата
        сметка на Brojki и за сите трошоци направени од Вашата сметка на Brojki. Акредитациите за пристап се сопственост
        на Brojki и може да бидат отповикани ако ги споделите со која било трета страна (освен како што е дозволено во
        Условите), ако се компромитирани, ако ги прекршите овие Услови или ако Brojki ги прекине Условите на
        апликацијата.
      </Text>
      <Text>Понатаму, како услов за користење на Услугите:</Text>
      <Text>
        не се обидувајте да ја поткопате безбедноста или интегритетот на компјутерските системи или мрежи на Brojki или,
        каде што апликацијата или услугите се хостирани од трето лице, компјутерските системи и мрежите на таа трета
        страна;
      </Text>
      <Text>
        да не ја користите или злоупотребувајте апликацијата или Услугите на кој било начин што може да ја наруши
        функционалноста на апликацијата или другите системи што се користат за доставување на Услугите или да ја нарушат
        способноста на кој било друг корисник да ги користи услугите или апликацијата;
      </Text>
      <Text>
        не се обидувајте да стекнете неовластен пристап до какви било материјали освен оние на кои ви е дадена изречна
        дозвола за пристап или до компјутерскиот систем на кој се сместени апликацијата или услугите;
      </Text>
      <Text>
        не користете ги услугите или апликациите за пренос на какви било: датотеки што можат да оштетат компјутерски
        уреди или софтвер на кое било друго лице, содржина што може да биде навредлива или материјал или податоци што
        прекршуваат кој било закон (вклучувајќи податоци или друг материјал заштитен со авторски права или трговски
        тајни кои немате право на користење); и
      </Text>
      <Text>
        не се обидувајте да менувате, копирате, приспособите, репродуцирате, расклопите, декомпајлирате или
        контра-инженеризирате какви било компјутерски програми што се користат за доставување на Услугите, освен кога е
        строго неопходно да се користи која било од нив за нормално функционирање.
      </Text>
      <Heading size="md">3. Надоместоци; Давачки; Данок на додадена вредност (ДДВ)</Heading>
      <Text>
        Надоместоците за претплата и сите други трошоци за користење на апликацијата се опишани на страницата. Тие може
        да се менуваат од време на време. Доколку се променат надоместоците за претплата, ќе ви дадеме известување
        најмалку 30 дена. Ако тие навистина се променат, Вашата континуирана употреба на Апликацијата по промената
        укажува на вашиот договор со новите даноци и давачки по датумот на стапување на сила на промената. Секоја
        промена на даноци и други давачки нема да се применува на периодот на наплата во кој се случува промената.
        Надоместоците за платежни трансакции се предмет на промена без најава, освен ако не е поинаку забрането, по
        дискреција на нашите партнери на платформата. Тековните цени може да се најдат овде.
      </Text>
      <Text>
        Иако големо внимание се посветува при изработката на страницата и опис на Услугите, може да се појават печатни,
        илустративни грешки или грешки во цените. Го задржуваме правото да ги исправиме грешките во секое време. Сите
        цени се цитирани и се плаќаат во македонски денари или евра. Вие сте одговорни за сите даноци што се применуваат
        и давачките во која било применлива јурисдикција.
      </Text>
      <Heading size="md">4. Сопственост, авторски права и трговски марки</Heading>
      <Text>
        Во овие Услови содржината достапна преку Услугите, вклучувајќи ги сите информации, податоци, логоа, ознаки,
        дизајни, графики, слики, звучни датотеки, други датотеки и нивниот избор и распоред, се нарекува „содржина“.
        Содржините обезбедени од корисниците, без разлика дали се претплатници, клиенти или други корисници, се
        нарекуваат „корисничка содржина“. На пример, податоците за неговите клиенти и за записите во временскиот лист
        што претплатникот им ги дава на услугите се корисничка содржина на тој претплатник, податоците за неговите
        плаќања што клиентот ги дава на услугите се корисничка содржина на тој клиент и содржина што корисникот ја
        внесува во форумот Brojki или внесува како коментар на блогот на Brojki е корисничка содржина на корисникот.
      </Text>
      <Text>
        Корисничката содржина е сопственост на тој корисник. Единственото право на Brojki на таа корисничка содржина се
        ограничените лиценци за неа дадени во овие Услови. Тие лиценци се опишани во Дел 5 и Дел 15 од овие Услови.
      </Text>
      <Text>
        Освен корисничката содржина, Услугите, целата содржина и целиот софтвер достапен на или во однос на Услугите или
        што се користат за креирање и управување со Услугите (вклучувајќи, без ограничување, апликацијата на Brojki) се
        сопственост на Brojki или неговите даватели на лиценца и е заштитен со северно-македонски и меѓународни закони
        за авторски права, и сите права на Услугите, таквата содржина и таков софтвер се изрично задржани. Сите трговски
        марки, регистрирани трговски марки, имиња на производи и имиња на компании или логоа споменати во Услугите се
        сопственост на нивните соодветни сопственици. Упатувањето на какви било производи, услуги, процеси или други
        информации, по трговско име, трговска марка, производител, добавувач или на друг начин не претставува или
        имплицира нивно одобрување, спонзорство или препорака од страна на Brojki.
      </Text>
      <Text>
        Вашата корисничка содржина е Ваша одговорност. Ние немаме никаква одговорност или одговорност за тоа, или за
        каква било загуба или штета што Вашата корисничка содржина може да ја предизвика на вас или на други луѓе. Иако
        немаме обврска да го сториме тоа, ние имаме единствено дискреционо право да отстраниме, прегледаме или уредуваме
        без известување која било Корисничка содржина објавена или складирана на Услугите, и тоа може да го направиме во
        секое време и од која било причина. Вие сте единствено одговорни за одржување копии и замена на која било
        корисничка содржина што ја објавувате или складирате на Услугите. Ако овластувате трети страни да пристапуваат
        до Вашата корисничка содржина преку услугите, вклучително и преку Brojki апликацијата, се согласувате дека ни е
        дозволено да им ја обезбедиме корисничката содржина, а исто така се согласувате дека немаме одговорност или
        одговорност за нивната употреба на таквиот корисник на содржина.
      </Text>
      <Heading size="md">5. Вашата ограничена лиценца за вашата корисничка содржина на Brojki</Heading>
      <Text>
        Ние не бараме никаков сопственички интерес за Вашата корисничка содржина, но ни треба правото да ја користиме
        Вашата корисничка содржина до степенот што е неопходен за да работиме на страницата и да ги обезбедуваме
        услугите, сега и во иднина. На пример, ако оставите коментари на блогот на Brojki или објавите на форумот на
        Brojki, потребна ни е Вашата лиценца за прикажување на таа корисничка содржина на услугите и ни треба право да
        ја подлиценцираме таа корисничка содржина на нашите други корисници за да можат да ја гледаат таа корисничка
        содржина. Ако сте претплатник и внесувате податоци од временскиот лист во врска со клиент и ја конфигурирате
        Вашата сметка да прикажува податоци од временскиот лист на вашите клиенти, потребна ни е Вашата лиценца за таа
        корисничка содржина за да ја користиме во работењето на Услугите и ни треба право да подлиценцирајте ја таа
        корисничка содржина на вашите клиенти за таа цел.
      </Text>
      <Text>Со објавување или дистрибуирање на корисничка содржина до или преку Услугите, вие</Text>
      <Text>
        <strong>(а)</strong> им давате на Brojki и неговите филијали и подружници неексклузивно, без авторски права,
        преносливо право за користење, прикажување, изведување, репродукција, дистрибуција, објавување, менување ,
        адаптираат, преведуваат и создаваат изведени дела од таквата корисничка содржина, на начин и за целите за кои
        Услугите од време на време ја користат таквата корисничка содржина;
      </Text>
      <Text>
        <strong>(б)</strong> претставувате и гарантирате дека <strong>(I)</strong> ги поседувате и контролирате сите
        права на корисничката содржина што ја објавувате или на друг начин ја дистрибуирате, или на друг начин имате
        законско право да ја објавувате и дистрибуирате таа корисничка содржина до или преку Услугите; и{' '}
        <strong>(II)</strong> употребата и објавувањето или друг пренос на таквата корисничка содржина не ги прекршува
        овие Услови и нема да ги наруши правата или да предизвика повреда на кое било лице или ентитет.
      </Text>
      <Text>
        Ако вашата корисничка содржина е наменета за употреба на други корисници (на пример, ако издавате фактури на
        клиенти или овластувате трети страни да пристапуваат до вашата корисничка содржина преку Brojki апликацијата),
        вие исто така ни давате нам и на нашите филијали и подружници неексклузивни , без авторски права, преносливо
        право да се подлиценцира таквата корисничка содржина на таквите корисници за нивна употреба во врска со нивната
        употреба на Услугите, како што е опишано во овие Услови.
      </Text>
      <Text>
        Овие лиценци од вас се не-ексклузивни бидејќи имате право да ја користите Вашата корисничка содржина на друго
        место. Тие се без авторски права бидејќи од нас не се бара да Ви платиме за користењето на вашите кориснички
        содржини на услугите. И тие се преносливи бидејќи ни треба правото да ги пренесеме овие лиценци на кој било
        оператор-наследник на Услугите. Нашите права за „измена, прилагодување, преведување и создавање од изведени дела
        се неопходни затоа што нормалното функционирање на Услугите го прави тоа со вашата корисничка содржина кога ја
        обработува за употреба во Услугите.
      </Text>
      <Heading size="md">6. Нашата ограничена лиценца за Вашата содржина</Heading>
      <Text>
        Brojki Ви дава ограничена, отповиклива, не-ексклузивна, недозволена лиценца за пристап до Услугите и за
        прегледување, копирање и печатење на делови од содржините што ви се достапни преку Услугите. Таквата лиценца
        подлежи на овие Услови и конкретно е условена со следново:
      </Text>
      <Text>
        <strong>(I)</strong> вие можете само да ги гледате, копирате и печатите таквите делови од содржината за Ваша
        сопствена употреба;
      </Text>
      <Text>
        <strong>(II)</strong> не смеете да менувате или на друг начин да правите изведени дела на содржината, или да ја
        репродуцирате, дистрибуирате или прикажувате содржината (освен за кеширање(cache) на страна и старници), освен
        како што е изрично дозволено во овие Услови;
      </Text>
      <Text>
        <strong>(III)</strong> не смеете да отстранувате или менувате какви било авторски права, заштитен знак или други
        известувања за сопственост што биле ставени во содржината;
      </Text>
      <Text>
        <strong>(IV)</strong> не смеете да користите какви било методи за ископување податоци, роботи или слични методи
        за собирање или извлекување податоци;
      </Text>
      <Text>
        <strong>(V)</strong> не смеете да ги користите услугите или содржината освен за нивната намена.
      </Text>
      <Text>
        Освен како што е изрично дозволено погоре, секоја употреба на кој било дел од содржината без претходна писмена
        дозвола од нејзиниот сопственик е строго забранета и ќе ја прекине лиценцата дадена во овој Дел, овој Договор и
        Вашата сметка со нас. Секоја таква неовластена употреба може, исто така, да ги прекрши важечките закони,
        вклучително и без ограничување законите за авторски права и трговски марки. Освен ако не е експлицитно наведено
        овде, ништо во овие Услови не може да се толкува како давање каква било лиценца за правата на интелектуална
        сопственост, без разлика дали со отфрлање, импликација или на друг начин. Лиценцата во овој дел е отповикана од
        Brojki во секое време. Вие застапувате и гарантирате дека Вашата употреба на Услугите и содржината ќе биде
        конзистентна со оваа лиценца и нема да ги прекршува или прекршува правата на која било друга страна или
        прекршува договор или законска должност кон која било друга страна, или прекршува кој било применлив закон во
        Северна Македонија, држава во Еворпска Унија и која било земја во која ги користите Услугите. Овие закони
        вклучуваат економски санкции. „Економски санкции“ се резолуции на Советот за безбедност на Обединетите нации со
        кои се бара од државите да ги ограничат или забранат деловните активности со одредени странски држави, поединци
        и ентитети на тие држави и недржавни актери, како и законите, регулативите и политиките кои ги ограничуваат или
        забрануваат деловните активности со одредени странски држави, поединци и субјекти на тие држави и недржавни
        актери.
      </Text>
      <Text>
        За да побарате дозвола за користење на содржини што не се вклучени во оваа лиценца, можете да контактирате со
        Brojki на адресата наведена на последната страна на овие Услови.
      </Text>
      <Heading size="md">7. Користење на интерактивни области и услуги</Heading>
      <Text>
        Услугите може да вклучат форуми за дискусија, огласни табли, услуги за прегледување или други форуми во кои Вие
        или трети страни може да објавувате рецензии или друга содржина, пораки, материјали или други ставки на Услугите
        („Интерактивни области“). Ако Brojki обезбедува такви интерактивни области, Вие сте единствено одговорни за
        Вашето користење на таквите интерактивни области и ги користите на Ваш сопствен ризик. Корисничката содржина
        поднесена на која било јавна област на Услугите ќе се смета за недоверлива. Се согласувате да не објавувате,
        прикачувате на, пренесувате, дистрибуирате, складирате, создавате или на друг начин објавувате преку Услугите
        кое било од следново:
      </Text>
      <Text>
        секоја порака, податоци, информации, текст, музика, звук, фотографии, графика, код или друг материјал што е
        незаконски, клевета, клеветнички, непристоен, порнографски, непристоен, развратен, сугестивен, вознемирувачки,
        заканувачки, навредлив на приватноста или правата на публицитет, навредлив, воспалителен, лажен или на друг
        начин непристоен;
      </Text>
      <Text>
        содржина која би претставувала, поттикнувала или дава инструкции за кривично дело, ги нарушува правата на која
        било страна или на друг начин би создала одговорност или прекршува кое било локално, државно, национално или
        меѓународно право;
      </Text>
      <Text>
        содржина што може да прекрши кој било патент, заштитен знак, трговска тајна, авторско право или друго
        интелектуално или сопственичко право на која било страна;
      </Text>
      <Text>
        содржина што претставува лимит на кое било лице или ентитет или на друг начин погрешно ја претставува вашата
        поврзаност со лице или ентитет;
      </Text>
      <Text> непосакувани унапредувања, политичка кампања, рекламирање или молби;</Text>
      <Text>
        приватни информации на која било трета страна, вклучувајќи, без ограничување, адреси, телефонски броеви, адреси
        на е-пошта и броеви на кредитни картички, освен ако таа трета страна изречно се согласила на таквата употреба;
      </Text>
      <Text>вируси, оштетени податоци или други штетни, нарушувачки или деструктивни датотеки;</Text>
      <Text>
        содржина што не е поврзана со темата на интерактивната област(и) во кои се објавуваат таквите содржини; или
      </Text>
      <Text>
        содржина која, според мислењето на Brojki, е непристојна или која го ограничува или спречува кое било друго лице
        да ги користи или ужива во интерактивните области или услугите, или што може да ги изложи Brojki или неговите
        подружници или неговите корисници на каква било штета или одговорност од кој било вид.
      </Text>
      <Text>
        „нулта толеранција“ политика кон СПАМ: Не смеете да ги користите интерактивните области или услугите генерално
        за да испраќате комерцијални или други пораки до трета страна доколку тие пораки не се побарани, овластени или
        добредојдени од трето страна, а при користењето на Услугите мора да се придржувате до сите важечки закони,
        вклучувајќи ги законите што се применуваат во која било јурисдикција за SPAM-от (или спамирање) и маркетинг
        практиките, како и со сите применливи упатства за маркетинг асоцијација за етичките маркетинг практики.
      </Text>
      <Text>
        Секое користење на Интерактивните области или други делови од Услугите што ги прекршува горенаведените ги
        прекршува овие Услови и може да резултира, меѓу другото, со прекинување или суспендирање на Вашите права за
        користење на интерактивните области и/или услугите.
      </Text>
      <Heading size="md">8. Обезбедување доверлива и сигурна услуга</Heading>
      <Text>
        Ние сериозно ги сфаќаме безбедноста и доверливоста и даваме голем напор за да се осигураме дека нашите услуги
        работат постојано и дека се безбедни средини за Вашите податоци. Ние ги користиме она што веруваме дека се
        „најдобрите“ хостинг услуги и безбедносни технологии и услуги за кои веруваме дека ви обезбедуваат безбедна
        средина. Повеќе информации за овие услуги и технологии се достапни на страницата.
      </Text>
      <Text>
        Сепак, ниту еден систем не е совршено безбеден или доверлив, интернетот е инхерентно небезбеден медиум, а
        доверливоста на услугите за хостирање, интернет посредниците, Вашиот давател на интернет услуги и другите
        даватели на услуги не може да се обезбеди. Кога користите Brojki, ги прифаќате овие ризици и одговорноста да
        изберете да користите технологија која не обезбедува совршена безбедност или доверливост.
      </Text>
      <Heading size="md">9. Brojki нема одговорност за услуги, страници или содржини од трета страна</Heading>
      <Text>
        Како услуга за нашите корисници, страницата може да содржи линкови до веб-страници на трети лица („страници на
        трети лица“) или содржина од трета страна („содржина од трета страна“) и може да обезбедува услуги од трети лица
        („трета- странска/и услуги“), вклучувајќи процесори за плаќање и други посредници за плаќање што може да ги
        користите во врска со користењето на Услугите. Користите сајтови од трета страна, содржина од трета страна
        (заедно, „материјали од трета страна“) или услуги од трета страна на Ваш сопствен ризик.
      </Text>
      <Text>
        Brojki не поднесува никакво тврдење или застапување во врска со материјалите од трети лица и услугите на трети
        лица, и ги обезбедува или поврзува со нив само како погодност. Вклучувањето во Услугите на материјали од трета
        страна не подразбира одобрување, усвојување или спонзорство од страна на Brojki на, или поврзаност со такви
        материјали од трета страна. Brojki не прифаќа никаква одговорност за прегледување на промените или ажурирањата
        на, или квалитетот, содржината, политиките, природата или веродостојноста на материјалите од трета страна или
        веб-локациите што се поврзуваат со Услугите. Кога ќе ги напуштите Услугите, нашите услови и политики повеќе не
        владеат, а кога користите услуги од трета страна, важат нивните услови и политики. Треба да ги разгледате
        важечките услови и политики, вклучително и практиките за приватност и собирање податоци, за какви било
        материјали од трета страна и услуги од трета страна, и треба да направите каква било истрага што сметате дека е
        потребна или соодветна пред да продолжите со каква било трансакција со трета страна. Примери за такви трети
        добавувачи се дадени во распоредот на овие Услови со наслов „трети даватели на услуги“.
      </Text>
      <Text>
        Давателот на материјали од трета страна и услуги од трети лица е единствено одговорен за таквите материјали и
        услуги од трети лица, содржината во нив, какви било гаранции до степен до кој таквите гаранции не се отфрлени,
        каква било обука, поддршка или одржување за материјали од трета страна и услуги од трета страна, и какви било
        тврдења што вие или која било друга страна може да ги имате во врска со тие материјали од трета страна и услуги
        од трета страна или вашата употреба на нив. Потврдувате дека ја купувате лиценцата за материјали од трета страна
        и услуги од трета страна од давателот на тие материјали од трета страна и услуги од трета страна; Brojki делува
        како застапник на давателот во обезбедувањето на такви материјали од трета страна и услуги од трета страна за
        вас; Brojki не е страна на лиценцата помеѓу вас и давателот на услуги во однос на таквите материјали и услуги од
        трета страна; и Brojki не е одговорен за таквите материјали од трети лица и услуги од трети лица, содржината во
        нив, или какви било гаранции или тврдења што вие или која било друга страна може да ги имате во врска со таквите
        материјали и услуги од трета страна или вашата употреба на нив.
      </Text>
      <Text>
        Вие потврдувате и се согласувате дека апликацијата Brojki и нејзините филијали се трети лица корисници на
        Договорот за лиценца за краен корисник за такви материјали од трета страна и услуги од трета страна, и дека, по
        вашето прифаќање на условите и условите на лиценцата за кое било такво трето- материјали од страна и услуги од
        трета страна Brojki ќе има право (и ќе се смета дека го прифатила правото) да ја спроведе таквата лиценца против
        Вас како нејзин корисник на трета страна.
      </Text>
      <Heading size="md">10. Реклами и Промоции</Heading>
      <Text>
        Brojki може да прикажува реклами и промоции од трети страни на Услугите. Вашите деловни зделки или
        кореспонденција со, или учество во промоции на огласувачи, освен Brojki, и сите услови, гаранции или застапувања
        поврзани со таквите зделки, се исклучиво помеѓу вас и третата страна. Brojki не е одговорен за каква било загуба
        или штета настаната како резултат на такви зделки или како резултат на присуството на трети лица огласувачи на
        Услугите.
      </Text>
      <Heading size="md">11. Електронски потпис</Heading>
      <Text>
        Brojki бара ваша согласност за користење на вашиот електронски снимен потпис во Апликацијата. Вашиот електронски
        снимен потпис ќе претставува ваше потврдување дека ги прифаќате условите овде и ќе се користи за создавање
        правно обврзувачки договор. Оваа снимена потврда од вас ќе претставува ваш потпис.
      </Text>
      <Heading size="md">12. Одрекување од гаранција</Heading>
      <Text>
        ВАШЕТО КОРИСТЕЊЕ НА САЈТОТ, УСЛУГИТЕ, СОФТВЕРОТ (ВКЛУЧУВАЈЌИ БЕЗ ОГРАНИЧУВАЊЕ, АПЛИКАЦИЈАТА) И СОДРЖИНАТА
        (КОЛЕКТИВНО, „BROJKI УСЛУГИ“) И СЕКОЈ МАТЕРИАЛ ОД ТРЕТА СТРАНА ИЛИ УСЛУГА Е ПОД ВАШ СОПСТВЕН РИЗИК, И ОСВЕН КАКО
        ШТО Е ОПИШАНО ВО ОВОЈ ДОГОВОР, СЕ ОБЕЗБЕДЕНИ „КАКО ШТО СЕ“. ДО МАКСИМАЛЕН СТЕПЕН ДОЗВОЛЕН СО ВАЖЕЧКИОТ ЗАКОН,
        BROJKI, НЕГОВИТЕ ПОДРУЖНИЦИ И ПОДРУЖНИЦИ И НИВНИТЕ ТРЕТИ ЛИЦА ДАВАТЕЛИ, ЛИЦЕНЦА, ДИСТРИБУТЕРИ ИЛИ ДОБАВУВАЧИ
        (КОЛЕКТИВНО, ПОСЕБНО) ESS ИЛИ ИМПЛИЦИРАНО, ВКЛУЧУВАЈЌИ ГАРАНЦИЈА ИЛИ УСЛОВ ДЕКА РЕШЕНИЕ НА BROJKI ИЛИ МАТЕРИЈАЛИ
        ИЛИ УСЛУГИ НА ТРЕТИ СТРАНИ СЕ ИЛИ ЌЕ БИДАТ (А) СООДВЕТНИ ЗА ПОСЕДНА ЦЕЛИ, (Б) ИМААТ ДОБРА НАСТАВА, (В) БИДЕТЕ СО
        ТРГОВСКИ КВАЛИТЕТ; ИЛИ ТИЕ НЕ ИЛИ НЕМА ДА СЕ МЕШАТ ИЛИ ПОВРЕДУВААТ ИЛИ ПРИСВОЈУВААТ СЕКОЕ ПРАВО ОД ИНТЕЛЕКТУАЛНА
        СОПСТВЕНОСТ. ПОНАТАМУ, ДОБАВУВАЧИТЕ ОДГОВОРУВААТ ЗА СИТЕ ГАРАНЦИИ И УСЛОВИ, ИЗРАЗНИ ИЛИ ИМПЛИЦИРАНИ ЗА ТОЧНОСТА,
        СИГУРНОСТА, КВАЛИТЕТОТ НА СОДРЖИНАТА ВО ИЛИ ПОВРЗАНИ СО РЕШЕНИЕТО НА BROJKI. BROJKI, НЕГОВИТЕ ПОДРУЖНИЦИ И
        НЕГОВИТЕ ПРИРАЧНИЦИ И ДОБАВУВАЧИТЕ НЕ ГАРАНТУВААТ ДЕКА РЕШЕНИЕТО НА BROJKI Е ИЛИ ЌЕ БИДЕ БЕЗБЕДНО, СЛОБОДЕН ОД
        БУБАЧКИ, ВИРУСИ, ПРЕКИН, И ГУБЕЊЕ НА ПОДАТОЦИ, BROJKI И НЕГОВИТЕ ПОДРУЖНИЦИ И ДРУГИ ДОБАВУВАЧИ ОДГОВАРААТ ЗА
        СЕКОЕ ПРЕТСТАВУВАЊЕ ИЛИ ГАРАНЦИЈА ДЕКА ВАШЕТО КОРИСТЕЊЕ НА УСЛУГИТЕ ЌЕ ГИ ЗАДОВОЛИ ИЛИ ОБЕЗБЕДИ ПОЧИТУВАЊЕ НА
        ЗАКОНСКИ ОБВРСКИ.
      </Text>
      <Heading size="md">113. Ограничување на одговорноста; Обесштетување</Heading>
      <Text>
        Вие се откажувате и нема да тврдите какви било тврдења или наводи од каква било природа против Brojki, неговите
        филијали или подружници, нивните спонзори, изведувачи, огласувачи, продавачи или други партнери, кој било од
        нивните наследници или доделени, или кој било од нивните соодветни службеници, директори, агенти или вработени
        (колективно, „ослободените страни“) кои произлегуваат од или на кој било начин се однесуваат на Вашата употреба
        на Brojki решенијата или какви било материјали од трета страна или услуги од трета страна, вклучувајќи, без
        ограничување, какви било побарувања или наводи во врска со прекршување на сопственички права или наводи дека
        која било ослободена страна има или треба да ве обештети, брани или смета за безопасни Вас или која било трета
        страна од какво било побарување или тврдење што произлегува од Вашата употреба или друга експлоатација на
        решението Brojki или какви било материјали од трета страна или услуги од трета страна. Ги користите Brojki
        решенијата или какви било материјали од трета страна или услуги од трета страна на Ваш сопствен ризик
      </Text>
      <Text>
        Без ограничување на горенаведеното, ниту Brojki ниту која било друга објавена страна нема да биде одговорна за
        каква било директна, посебна, индиректна, случајна, примерна, казнена или последователна штета или каква било
        друга штета од кој било вид, вклучително, но не ограничувајќи се на губење на употреба, загуба на добивка или
        загуба на податоци, без разлика дали се работи за договор, деликт (вклучувајќи, но не ограничувајќи се на
        небрежност) или на друг начин, кои произлегуваат од или на кој било начин поврзани со употребата или неможноста
        да се користи решението Brojki или кое било трет материјал или услуги од трета страна, вклучително без
        ограничување каква било штета предизвикана или што произлегува од Вашето потпирање на решението Brojki или какви
        било материјали од трета страна или услуги од трета страна или други информации добиени од Brojki или која било
        друга објавена страна или достапни преку решението Brojki или какви било материјали од трета страна или услуги
        од трета страна, или кои произлегуваат од грешки, грешки, пропусти, прекини, бришење датотеки или е-пошта,
        дефекти, вируси, доцнење во работењето или преносот или каков било неуспех во извршувањето, без разлика дали е
        резултат или не од Божји дела , неуспех на комуникациите, кражба, уништување или неовластен пристап до записите,
        програмите или услугите на Brojki или која било друга објавена страна.
      </Text>
      <Text>
        Во никој случај нема збирна одговорност на Brojki, без разлика дали е во договор, гаранција, деликт (вклучувајќи
        небрежност, без разлика дали е активна, пасивна или припишана), одговорност за производи, строга одговорност или
        друга теорија, која произлегува од или поврзана со употребата на решението Brojki го надминува секој надоместок
        платен од Вас за пристап или користење на Brojki решенијата во текот на трите месеци пред датумот на кое било
        побарување. Brojki во никој случај нема да има или да сноси каква било одговорност, без разлика дали е во
        договор, гаранција, деликт (вклучувајќи небрежност, без разлика дали е активна, пасивна или импутирана),
        одговорност за производи, строга одговорност или друга теорија, која произлегува од или поврзана со употребата
        на која било трета страна, материјали или услуги од трета страна.
      </Text>
      <Text>
        Ќе ги браните, обештетите и ќе ги држите безопасните Brojki и другите ослободени страни од какви било загуби,
        штети, обврски, трошоци, трошоци, побарувања и постапки кои произлегуваат од Вашата употреба на Brojki Solution
        или какви било материјали од трета страна или услуги од трета страна, и ако вие сте претплатник, од користењето
        на услугите од страна на клиентите и од употребата на Brojki Solution или било какви материјали од трета страна
        или услуги на трети лица од кое било лице на кое му давате пристап до Вашата сметка (вклучувајќи го персоналот
        или советниците), вклучувајќи ги и сите тврдењата направени од кое било лице дека некоја од Вашите, и ако сте
        претплатник, корисничката содржина на Вашите клиенти ги прекршува правата, вклучително и правата на
        интелектуална сопственост, на која било трета страна (колективно, „оштетени побарувања“). Brojki го задржува
        правото, по своја дискреција и на сопствен трошок, да ја преземе ексклузивната одбрана и контрола на сите
        обештетени побарувања. Се согласувате разумно да соработувате како што бара Brojki во одбраната на какви било
        обештетени побарувања.
      </Text>
      <Heading size="md">14. Комуникации</Heading>
      <Text>
        Предмет на Политиката за приватност, доколку испратите до Brojki или објавите на страницата на која било јавна
        област какви било информации, идеи, пронајдоци, концепти, техники или знаење („поднесоци од корисниците“), за
        која било цел, вклучително и развој, производство и/или маркетинг или производи или услуги кои вклучуваат такви
        информации, потврдувате дека Brojki може да ги користи поднесоците од корисниците без потврда или надомест од
        Вас и се откажувате од секое барање на сопственост или компензација или други права што можеби ги имате во врска
        со поднесоците од корисниците.
      </Text>
      <Heading size="md">15. Прекинување/измена на лиценцата и понудите на сајтот</Heading>
      <Box>
        <Text>
          Без оглед на која било одредба од овие Услови, Brojki го задржува правото, без известување и по сопствена
          дискреција, без никакво известување или обврска за вас, дa{' '}
        </Text>
        <Text>
          <strong>(а)</strong> ја прекине вашата лиценца за користење на услугите, или кој било дел од нив и правата на
          која било трета страна на која сте и дале пристап до вашата корисничка содржина
        </Text>
        <Text>
          <strong>(б)</strong> да го блокирате или спречите вашиот иден пристап и користење на сите или кој било дел од
          Услугите или содржината и правата на која било трета страна на која сте и дале пристап до вашите кориснички
          содржини
        </Text>
        <Text>
          <strong>(в)</strong> промени, суспендира или прекине кој било аспект од Услугите или содржината; и
        </Text>
        <Text>
          <strong>(г)</strong> да наметне ограничувања на услугите или содржината.
        </Text>
      </Box>
      <Heading size="md">16. Неактивни сметки; Раскинување на Договорот</Heading>
      <Text>
        Ако Вашата сметка е неактивна најмалку шест (6) месеци (утврдено од Brojki), може да ја деактивираме вашата
        сметка. Деактивираните сметки не се бришат - тие се ставени во складиште и може да се обноват. Ќе ве известиме
        преку е-мејл доколку одлучиме да ја деактивираме вашата сметка. Ако однапред знаете дека вашата сметка некогаш
        ќе биде неактивна и не сакате да ја деактивираме, известете нè однапред на info@brojki.com. Brojki го задржува
        правото да наплати надомест за складирање податоци за складирање на информации поврзани со деактивирани сметки.
        Ако по деактивирањето на Вашата сметка, таа остане неактивна и немаме слух за вас, можеме да ја прекинеме во
        секое време и без известување.
      </Text>
      <Text>
        Вие и Brojki може да го прекинете користењето на Услугите вклучувајќи го и Вашиот договор со овие Услови во
        секое време. Ако ја прекинете употребата на Услугите, мора да ги платите надоместоците што се применуваат за
        состојбата на тогашниот тековен период на наплата. Кога вашата сметка на Brojki е укината, Вашата корисничка
        содржина, набргу потоа, нема да се појави на услугите, освен за корисничките содржини испратени на јавни области
        на страницата како што се блогот, форумот или прегледите на производите, кои може да останат на страницата по
        раскинување. Ние, исто така, може да задржиме архивска копија од Вашата корисничка содржина по раскинувањето, а
        вие со ова ни давате неексклузивна, вечна, неотповиклива лиценца за одржување на таквата архивска копија за
        нашите внатрешни деловни цели.
      </Text>
      <Text>
        Доколку овие Услови истекуваат или прекинат поради која било причина, Деловите 4, 8, 9, 11, 13, 15 и и секое
        претставување или гаранција што ќе го направите во овие Услови, ќе опстојуваат на неодредено време.
      </Text>
      <Heading size="md">17. Разно</Heading>
      <Text>
        Доколку која било одредба од овие Услови се смета за незаконска, неважечка или поради било која причина
        неприменлива, тогаш таа одредба ќе се смета за раздвоена од овие Услови и нема да влијае на валидноста и
        спроведливостта на останатите одредби. Brojki може да ѝ додели некое или сите права од овие Услови на која било
        страна без Ваша согласност. Не ви е дозволено да доделите некое од Вашите права или обврски од споменатите без
        претходна писмена согласност од Brojki, и секој таков обид за доделување ќе биде неважечки и неспроведлив. Овие
        Услови го сочинуваат целиот договор помеѓу Вас и Brojki во врска со користењето на страницата, содржината,
        додатоците и услугите и ги заменуваат сите претходни или современи комуникации без разлика дали се електронски,
        усни или писмени помеѓу вас и Brojki во врска со нивното користење. Страните потврдуваат дека нивната желба е
        овие Услови, како и сите други документи во врска со овие Услови, вклучително и известувањата, да бидат и ќе
        бидат изготвени на македонски јазик.
      </Text>
      <Heading size="md">18. Прашања и коментари</Heading>
      <Box>
        <Text>
          Ако имате какви било прашања во врска со овие Услови или вашето користење на услугите, ве молиме контактирајте
          не овде:
        </Text>
        Brojki <br /> PSB Architects
        <br /> Полог, Ламела A1
        <br /> Локал 1, Тетово 1200 Тетово, С. Македонија <br />
        (+389) 070/ 929-505 (контакт телефон) <br />
        info@brojki.com
      </Box>
    </Flex>
  );
};
export const TermsConditionsDialog: React.FC<React.PropsWithChildren<IDisclosure>> = ({
  type = 'button',
  label = 'услови за користење',
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {type === 'button' ? <Button onClick={onOpen}>Услови за користење</Button> : null}
      {type === 'link' ? (
        <Button onClick={onOpen} mb="0" size="sm" variant="link" color="teal.500" fontWeight="bold">
          {label}
        </Button>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior="outside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <h1 className="h4 modal-title">Услови за користење</h1>
            <Text fontSize="xs" className="text-muted">
              Објавено: 25 Септември 2018 | Ажурирано: 01 Февруари 2024
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TermsConditionsBody />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Затвори
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
