import { createAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../Core/AppStore';
import { authService } from '../../Core/auth/authService';
import { TODO } from '../../Core/core.types';

export const signupRequest = createAction<string>('@auth/SIGNUP_REQUEST');
export const signupSuccess = createAction<TODO>('@auth/SIGNUP_SUCCESS');
export const signupError = createAction<{ errors: unknown }>('@auth/SIGNUP_ERROR');

interface SignupData {
  name: string;
  company_name: string;
  email: string;
  password: string;
  password_confirmation: string;
}
export const signup =
  ({ name, company_name, email, password, password_confirmation }: SignupData): AppThunk =>
  async (dispatch) => {
    dispatch(signupRequest(email));

    try {
      const data = await authService.signup({ name, company_name, email, password, password_confirmation });
      dispatch(signupSuccess(data));
      return data;
    } catch (response: TODO) {
      if (response?.data?.errors) {
        return dispatch(signupError({ errors: response.data.errors }));
      }
      return dispatch(
        signupError({
          errors: {
            message: 'Проблеми во системот 😞. Обидете се повторно подоцна!',
          },
        }),
      );
    }
  };
