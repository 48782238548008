import { addDays, eachMonthOfInterval, format, isValid, parseISO } from 'date-fns';

const MK_FORMAT = 'dd/MM/yyyy';

export const after15days = () => addDays(new Date(), 15).toISOString();
export const after1month = () => addDays(new Date(), 30).toISOString();
export const after3months = () => addDays(new Date(), 90).toISOString();
/**
 * Format date to 'dd/MM/yyyy' -> 04/04/2020
 */
export const toFormattedDate = (date?: string | Date | null): string | null | undefined => {
  if (date == null || date === '') {
    return null;
  }

  if (date instanceof Date) {
    // Check if the date is valid
    if (isValid(date)) {
      return format(date, MK_FORMAT);
    } else {
      return null;
    }
  }

  try {
    const parsedDate = parseISO(date);
    // Check if the parsed date is valid
    if (isValid(parsedDate)) {
      return format(parsedDate, MK_FORMAT);
    } else {
      return null;
    }
  } catch {
    return null;
  }
};

export const yearRange = (year = new Date().getFullYear()) =>
  eachMonthOfInterval({
    start: new Date(year, 0, 1),
    end: new Date(year, 11, 1),
  }).map((d) => format(d, 'yyyy-MM'));

export const isValidDate = (date: Date) => date && date instanceof Date && !isNaN(date.getTime());

/**
 * Given date in format YYYY-MM check if is same as current month
 * @param date string
 */
export const isCurrentMonthInShortFormat = (date: string) => date === format(new Date(), 'yyyy-MM');

export const getCurrentYear = () => new Date().getFullYear();

export const getFormattedDate = (date: Date | string) => {
  try {
    // Check if the input is a string and parse it as an ISO date string
    if (typeof date === 'string') {
      return parseISO(date);
      // if (isValidDate(parsedDate)) {
      //   return format(parsedDate, 'yyyy-MM-dd');
      // }
    }
    // If the input is a Date object, format it directly
    else if (date instanceof Date && isValid(date)) {
      return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
    }
  } catch (error) {
    console.error(error);
  }
  return '';
};

export const isValidMKDate = (value: string): boolean => {
  // Regular expression to match the date format 'dd/MM/yyyy'
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

  // Check if the value matches the date format
  const matches = value.match(regex);
  if (!matches) {
    return false;
  }

  // Extract day, month, and year from the matches
  const day = parseInt(matches[1], 10);
  const month = parseInt(matches[2], 10) - 1; // Subtract 1 to convert month to 0-based index
  const year = parseInt(matches[3], 10);

  // Create a date object with the extracted values
  const date = new Date(year, month, day);

  // Check if the date is valid and the components match the input values
  return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
};
