import { createReducer } from '@reduxjs/toolkit';
import { getCurrentUserFromLocalStorage } from '../../Core/utils/getCurrentUserFromLocalStorage';
import { AuthState } from './authentication.types';
import { loginError, loginRequest, loginSuccess } from './login.actions';
import { signupError, signupRequest, signupSuccess } from './signup.actions';

const [user] = getCurrentUserFromLocalStorage();

const initialState: AuthState = {
  isAuthenticated: user != null,
  user: user,
  isLoading: false,
  failures: {
    errors: null,
  },
};

export const authenticationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(loginRequest, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(loginSuccess, (state) => {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        failures: { errors: null },
      };
    })
    .addCase(loginError, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        failures: action.payload,
      };
    })
    .addCase(signupRequest, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(signupSuccess, (state, action) => {
      return {
        ...state,
        user: action?.payload,
        isLoading: false,
        isAuthenticated: true,
        failures: { errors: null },
      };
    })
    .addCase(signupError, (state, action) => {
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        failures: action?.payload,
      };
    });
});
