import { CashPayment } from '../../CashPayments/api/payments.types';

import { Creatable } from '../../Core/utils/standard.model';
import { Estimate } from '../../Estimates/api/estimates.types';
import { Expense } from '../../Expenses/api/expenses.types';
import { Invoice } from '../../Invoices/api/invoices.types';

export enum ClientType {
  Individual = 'Individual',
  Company = 'Company',
}

export interface Client extends Creatable {
  id: string;
  fullName?: string;
  businessName?: string;
  businessEmail?: string;
  contactAddress?: string;
  contactEmail?: string;
  contactPhone?: string;
  website?: string;
  businessId?: string;
  businessVatId?: string;
  businessBankAccount?: string;
  businessAddress?: string;
  type: ClientType;
  status?: string;
  avatarUrl?: string;
  invoices: Invoice[];
  estimates: Estimate[];
  payments: CashPayment[];
  expenses: Expense[];
}
