import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated } from '../../hooks/useIsAuthenticated';
import { Feature, useShouldEnableFeature } from '../../utils/shouldEnableFeature';

export const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isAuthenticated] = useIsAuthenticated();

  return isAuthenticated ? <>{children}</> : <Navigate to={{ pathname: '/login' }} />;
};

export const ShouldEnablePayments: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);

  return shouldEnablePayments ? <>{children}</> : null;
};
