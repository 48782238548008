import { Box, Tooltip } from '@chakra-ui/react';
import * as React from 'react';

interface MenuItemButtonProps {
  onClick: () => void;
  label: string;
  isCollapsed: boolean;
  icon: React.ReactElement;
}

export const MenuItemButton: React.FC<React.PropsWithChildren<MenuItemButtonProps>> = ({
  icon,
  label,
  onClick,
  isCollapsed = false,
}) => (
  <Box
    as="li"
    className="Sidebar-menu__item"
    role="button"
    onClick={onClick}
    fontSize="0.75rem"
    fontWeight="semibold"
    cursor="pointer"
  >
    <Box className="p1-2 pb-1 link" _focus={{ outline: 'none' }}>
      {isCollapsed ? (
        <Tooltip hasArrow aria-label={label} label={label} bg="white" color="#333" placement="right" zIndex={1000}>
          <Box display="flex" width="100%" alignItems="center" minHeight="20px">
            <Box verticalAlign="middle" textAlign="center" fontSize="20px" minW="20px" boxSize="28px">
              {icon}
            </Box>
            <span> {label} </span>
          </Box>
        </Tooltip>
      ) : (
        <Box display="flex" width="100%" alignItems="center" minHeight="20px">
          <Box verticalAlign="middle" textAlign="center" fontSize="20px" minW="20px" boxSize="28px">
            {icon}
          </Box>
          <span> {label} </span>
        </Box>
      )}
    </Box>
  </Box>
);

MenuItemButton.displayName = 'MenuItemButton';
