import * as React from 'react';
import { useSearchParams as useSearchParamsOrig } from 'react-router-dom';

// Remove when this is fixed https://github.com/remix-run/react-router/pull/10740
export function useSearchParams() {
  const [searchParams, setSearchParams] = useSearchParamsOrig();

  // Store a reference to the setter
  const setSearchParamsRef = React.useRef(setSearchParams);

  // Update the reference when the setter changes
  React.useEffect(() => {
    setSearchParamsRef.current = setSearchParams;
  }, [setSearchParams]);

  // Return a stable setter (which has no dep on searchParams)
  const setSearchParamsStable = React.useCallback(
    (...args: Parameters<typeof setSearchParams>) => setSearchParamsRef.current(...args),
    [],
  );

  const updateSearchParams = React.useCallback(
    (newParams: Record<string, string | number>) => {
      setSearchParamsStable((existingParams) => {
        const updatedParams = new URLSearchParams(existingParams);

        for (const [key, value] of Object.entries(newParams)) {
          updatedParams.set(key, String(value));
        }

        return updatedParams;
      });
    },
    [setSearchParamsStable],
  );

  return { searchParams, setSearchParams: setSearchParamsStable, updateSearchParams } as const;
}
