import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Kbd,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash-es/isEmpty';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { CashPayment } from '../../CashPayments/api/payments.types';
import { Client } from '../../Clients/api/clients.types';
import { isClientCompany } from '../../Clients/api/clients.utils';
import { HideOnMobile } from '../../Core/components/Helpers/HideOnMobile';
import { SpotlightLoader } from '../../Core/components/Loaders/SpotlightLoader';
import { Estimate } from '../../Estimates/api/estimates.types';
import { Expense } from '../../Expenses/api/expenses.types';
import { Invoice } from '../../Invoices/api/invoices.types';
import { Product } from '../../Products/api/products.types';
import { Project } from '../../Projects/api/projects.types';
import { useSearchQuery } from '../api/spotlight.api';
import { SpotlightEntities, SpotlightEntity, SpotlightNavigationItem } from '../api/spotlight.types';
import { SpotlightCashPaymentDetail } from './SpotlightCashPaymentDetail';
import { SpotlightClientDetail } from './SpotlightClientDetail';
import { SpotlightEstimateDetail } from './SpotlightEstimateDetail';
import { SpotlightExpenseDetail } from './SpotlightExpenseDetail';
import { SpotlightInvoiceDetail } from './SpotlightInvoiceDetail';
import { SpotlightMasterList } from './SpotlightMasterList';
import { SpotlightProductDetail } from './SpotlightProductDetail';
import { SpotlightProjectDetail } from './SpotlightProjectDetail';

import {
  faAddressBook,
  faBuilding,
  faCalculatorSimple,
  faChartUser,
  faCog,
  faCreditCardFront,
  faFileInvoice,
  faFlask,
  faRectangleHistoryCircleUser,
  faScannerKeyboard,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { highlightTerm } from '../../Core/utils/highlighter';
import { Feature, useShouldEnableFeature } from '../../Core/utils/shouldEnableFeature';
import './Spotlight.scss';

function useDebounce(value: string, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed
      // within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const navigationHits: SpotlightNavigationItem[] = [
  { id: '100', label: 'Инфо Панел', icon: faChartUser, path: '/dashboard', type: 'navigation-item' },
  { id: '101', label: 'Фактури', icon: faFileInvoice, path: '/invoices', type: 'navigation-item' },
  { id: '102', label: 'Пресметки', icon: faCalculatorSimple, path: '/estimates', type: 'navigation-item' },
  // { id: '103', label: 'Каса Прими', icon: faCashRegister, path: '/cash-payments', type: 'navigation-item' },
  { id: '104', label: 'Расходи', icon: faCreditCardFront, path: '/expenses', type: 'navigation-item' },
  { id: '105', label: 'Клиенти', icon: faAddressBook, path: '/clients', type: 'navigation-item' },
  { id: '106', label: 'Проекти', icon: faFlask, path: '/projects', type: 'navigation-item' },
  { id: '107', label: 'Производи и Услуги', icon: faScannerKeyboard, path: '/products', type: 'navigation-item' },
  { id: '108', label: 'Компанија', icon: faBuilding, path: '/company/profile', type: 'navigation-item' },
  { id: '109', label: 'Подесувања', icon: faCog, path: '/company/settings', type: 'navigation-item' },
  { id: '110', label: 'Корпа', icon: faTrashCan, path: '/company/trash', type: 'navigation-item' },
];

const initialData: SpotlightEntities = {
  invoices: [],
  estimates: [],
  payments: [],
  expenses: [],
  clients: [],
  projects: [],
  products: [],
};

interface Props {
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
  onToggle?: () => void;
}

const isInvoice = (entity: SpotlightEntity | null): entity is Invoice =>
  (entity as Invoice)?.invoiceNumber !== undefined;

const isEstimate = (entity: SpotlightEntity | null): entity is Estimate =>
  (entity as Estimate)?.estimateNumber !== undefined;

const isExpense = (entity: SpotlightEntity | null): entity is Expense =>
  (entity as Expense)?.expenseNumber !== undefined;

const isCashPayment = (entity: SpotlightEntity | null): entity is CashPayment =>
  (entity as CashPayment)?.referenceNumber !== undefined;

const isClient = (entity: SpotlightEntity | null): entity is Client => (entity as Client)?.businessName !== undefined;

const isProject = (entity: SpotlightEntity | null): entity is Project => (entity as Project)?.code !== undefined;

const isProduct = (entity: SpotlightEntity | null): entity is Product =>
  !!(entity && (entity as Product).skuCode !== undefined);

const isNavigationItem = (entity: SpotlightEntity | null): entity is SpotlightNavigationItem =>
  (entity as SpotlightNavigationItem)?.path !== undefined;

export const Spotlight: React.FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);
  const [shouldViewAudits] = useShouldEnableFeature(Feature.ViewAudits);
  const [selectedEntity, setSelectedEntity] = useState<SpotlightEntity | null>(null);

  const debouncedQuery = useDebounce(query, 500);

  const { data: entities, isLoading, isFetching, error } = useSearchQuery(debouncedQuery);

  const {
    invoices = [],
    estimates = [],
    expenses = [],
    payments = [],
    clients = [],
    projects = [],
    products = [],
  } = entities || initialData;

  const navigationItems: SpotlightNavigationItem[] = React.useMemo(
    () =>
      shouldViewAudits
        ? [
            ...navigationHits,
            {
              id: '111',
              label: 'Ревизија',
              icon: faRectangleHistoryCircleUser,
              path: '/company/audits',
              type: 'navigation-item',
            },
          ]
        : navigationHits,
    [shouldViewAudits],
  );

  const hits: SpotlightEntity[] = React.useMemo(
    () => [
      ...(query ? [] : navigationItems),
      ...invoices,
      ...estimates,
      ...expenses,
      ...(shouldEnablePayments ? payments : []),
      ...clients,
      ...projects,
      ...products,
    ],
    [
      navigationItems,
      query,
      invoices,
      estimates,
      expenses,
      payments,
      clients,
      projects,
      products,
      shouldEnablePayments,
    ],
  );

  const setSelected = (entity: SpotlightEntity | null) => setSelectedEntity(entity);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(null);
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (!isLoading && hits.length > 0) {
      setSelected(hits[0]);
    }
  }, [isLoading, hits]);

  const navigateToItem = (item: SpotlightEntity | null) => {
    if (isNavigationItem(item)) {
      return navigate(item.path);
    }
    if (isInvoice(item)) {
      return navigate(`/invoices/${item.id}?light=true`);
    }
    if (isEstimate(item)) {
      return navigate(`/estimates/${item.id}?light=true`);
    }
    if (shouldEnablePayments) {
      if (isCashPayment(item)) {
        return navigate(`/cash-payments/${item.id}?light=true`);
      }
    }
    if (isExpense(item)) {
      return navigate(`/expenses/${item.id}?light=true`);
    }
    if (isClient(item)) {
      return navigate(`/clients/${item.id}?light=true`);
    }
    if (isProject(item)) {
      return navigate(`/projects/${item.id}?light=true`);
    }
    if (isProduct(item)) {
      return navigate(`/products/${item.id}?light=true`);
    }
  };

  const navigateTo = (item: SpotlightEntity | null) => {
    if (!item) {
      return;
    }

    navigateToItem(item);
    onClose?.();
  };

  const index = React.useMemo(() => hits.findIndex((i) => selectedEntity?.id === i.id), [hits, selectedEntity?.id]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key) || isEmpty(hits)) {
      return;
    }

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown': {
        event.preventDefault();
        const newIndex = event.key === 'ArrowUp' ? Math.max(0, index - 1) : Math.min(hits.length - 1, index + 1);
        setSelected(hits[newIndex]);
        break;
      }
      case 'Enter': {
        if (selectedEntity) {
          onClose?.();
          navigateTo(selectedEntity);
        }
        break;
      }
    }
  };

  return (
    <Modal
      onCloseComplete={() => {
        setQuery('');
        setSelected(null);
      }}
      size={isMobile ? 'full' : '2xl'}
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent className="spotlight" onKeyDown={onKeyDown} borderRadius={isMobile ? 'none' : 'md'}>
        {isMobile ? <ModalCloseButton /> : null}
        <ModalBody overflow="hidden" mt={isMobile ? 8 : 0} minHeight={query ? '420px' : 'auto'}>
          <Box backgroundColor="white" display="flex" alignItems="center" justifyContent="center" width="100%" my={2}>
            <InputGroup p={0}>
              <InputLeftElement
                pointerEvents="none"
                color="gray.400"
                children={<FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />}
              />
              <Input
                value={query || ''}
                onChange={onSearch}
                onKeyDown={onKeyDown}
                type="text"
                id="spotlightInput"
                name="spotlight"
                placeholder="Брзо Пребарување"
                autoComplete="off"
              />
            </InputGroup>
          </Box>
          <div className="row">
            <div className="col">
              {(isLoading || isFetching) && query && isEmpty(hits) ? <SpotlightLoader /> : null}
              {error ? <Text>Error</Text> : null}
            </div>
          </div>
          {isEmpty(hits) && !selectedEntity && !isLoading && !isFetching && query ? (
            <Flex direction="column" p={4} minHeight="300px" justifyContent="center">
              <Text>
                Не е пронајден ниеден документ со вашето пребарување "<strong>{query}</strong>"
              </Text>
              <Text mt={4}>Предлози:</Text>
              <UnorderedList mt={2} spacing={2}>
                <ListItem>Проверете дали сите зборови се напишани правилно.</ListItem>
                <ListItem>Обидете се со различни клучни зборови.</ListItem>
                <ListItem>Обидете се со поспецифични клучни зборови.</ListItem>
              </UnorderedList>
            </Flex>
          ) : null}
          <SimpleGrid columns={{ sm: 1, md: query ? 2 : 1 }} spacing="1rem">
            <Box maxHeight={isMobile ? `720px` : '540px'} pb={8} overflow="auto">
              {!query ? (
                <SpotlightMasterList
                  title="Навигација"
                  items={navigationItems}
                  setSelected={setSelected}
                  selected={selectedEntity}
                  onClick={navigateTo}
                >
                  {(item) => (
                    <>
                      <Box key={item.id} display="flex" alignItems="center" gap="4" paddingX="0.2rem">
                        <Icon
                          as={() => <FontAwesomeIcon icon={(item as SpotlightNavigationItem).icon} />}
                          color="green.500"
                        />
                        {(item as SpotlightNavigationItem).label}
                      </Box>
                    </>
                  )}
                </SpotlightMasterList>
              ) : (
                <>
                  <SpotlightMasterList
                    title="Фактури"
                    items={invoices}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => (
                      <>
                        <Text as="span">Бр. </Text>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: query
                              ? highlightTerm((item as Invoice).invoiceNumber, query)
                              : ((item as Invoice).invoiceNumber ?? ''),
                          }}
                        />
                      </>
                    )}
                  </SpotlightMasterList>
                  <SpotlightMasterList
                    title="Пресметки"
                    items={estimates}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => (
                      <>
                        <Text as="span">Бр. </Text>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: query
                              ? highlightTerm((item as Estimate).estimateNumber, query)
                              : ((item as Estimate).estimateNumber ?? ''),
                          }}
                        />
                      </>
                    )}
                  </SpotlightMasterList>
                  <SpotlightMasterList
                    title="Расходи"
                    items={expenses}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => (
                      <>
                        <Text as="span">Бр. </Text>
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: query
                              ? highlightTerm((item as Expense).expenseNumber, query)
                              : ((item as Expense).expenseNumber ?? ''),
                          }}
                        />
                      </>
                    )}
                  </SpotlightMasterList>

                  {shouldEnablePayments ? (
                    <SpotlightMasterList
                      title="Каса Прими"
                      items={payments}
                      setSelected={setSelected}
                      selected={selectedEntity}
                      onClick={navigateTo}
                    >
                      {(item) => (
                        <>
                          <Text as="span">Бр. </Text>
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: query
                                ? highlightTerm((item as CashPayment).referenceNumber, query)
                                : ((item as CashPayment).referenceNumber ?? ''),
                            }}
                          />
                        </>
                      )}
                    </SpotlightMasterList>
                  ) : null}

                  <SpotlightMasterList
                    title="Клиенти"
                    items={clients}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => {
                      const name = isClientCompany(item as Client)
                        ? (item as Client).businessName
                        : (item as Client).fullName;
                      return (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: query ? highlightTerm(name, query) : (name ?? ''),
                          }}
                        />
                      );
                    }}
                  </SpotlightMasterList>

                  <SpotlightMasterList
                    title="Проекти"
                    items={projects}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => (
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: query ? highlightTerm((item as Project).name, query) : ((item as Project).name ?? ''),
                        }}
                      />
                    )}
                  </SpotlightMasterList>

                  <SpotlightMasterList
                    title="Производи и Услуги"
                    items={products}
                    setSelected={setSelected}
                    selected={selectedEntity}
                    onClick={navigateTo}
                  >
                    {(item) => {
                      const label = `${(item as Product).skuCode} - ${(item as Product).name} `;
                      return (
                        <Text
                          dangerouslySetInnerHTML={{
                            __html: query ? highlightTerm(label, query) : (label ?? ''),
                          }}
                        />
                      );
                    }}
                  </SpotlightMasterList>
                </>
              )}
            </Box>
            <HideOnMobile>
              {!isEmpty(hits) ? (
                <Box className="my-4">
                  {isInvoice(selectedEntity) ? <SpotlightInvoiceDetail invoice={selectedEntity} query={query} /> : null}
                  {isEstimate(selectedEntity) ? (
                    <SpotlightEstimateDetail estimate={selectedEntity} query={query} />
                  ) : null}
                  {isExpense(selectedEntity) ? <SpotlightExpenseDetail expense={selectedEntity} query={query} /> : null}
                  {isCashPayment(selectedEntity) ? (
                    <SpotlightCashPaymentDetail cashPayment={selectedEntity} query={query} />
                  ) : null}
                  {isClient(selectedEntity) ? <SpotlightClientDetail client={selectedEntity} query={query} /> : null}
                  {isProject(selectedEntity) ? <SpotlightProjectDetail project={selectedEntity} query={query} /> : null}
                  {isProduct(selectedEntity) ? <SpotlightProductDetail product={selectedEntity} query={query} /> : null}
                </Box>
              ) : null}
            </HideOnMobile>
          </SimpleGrid>
        </ModalBody>
        <HideOnMobile>
          <ModalFooter p={0} pb={1} bg="gray.100">
            <div className="d-flex w-100 justify-content-around p-2 text-muted">
              <span>
                <Kbd color="black">&#8593;&#8595;</Kbd> за навигација
              </span>
              <span>
                <Kbd color="black">&crarr;</Kbd> за да изберете
              </span>
              <span>
                <Kbd color="black">esc</Kbd> затвори
              </span>
              <span>
                <Kbd color="black">cmd/ctrl+k</Kbd> отвори
              </span>
            </div>
          </ModalFooter>
        </HideOnMobile>
      </ModalContent>
    </Modal>
  );
};
