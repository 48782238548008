import { createReducer } from '@reduxjs/toolkit';
import { themeApplied } from './theme.actions';
import { Theme, ThemeState } from './theme.types';

const initialState: ThemeState = {
  name: Theme.Invoices,
  inputFocusBorderColor: 'sky.500',
  colorScheme: 'sky',
};

export const themeReducer = createReducer(initialState, (builder) => {
  builder.addCase(themeApplied, (state) => {
    return {
      ...state,
    };
  });
});
