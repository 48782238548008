import { createEntityAdapter } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { TODO } from '../../Core/core.types';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { http } from '../../Core/http/http';
import { TotalSummary } from '../../Dashboard/api/dashboard.types';
import { Client } from './clients.types';

const { ClientsUrl } = ApiEndpoints;

interface ClientResponse {
  data: Client;
}

export const uploadClientAvatar = async (
  { avatar, client }: { avatar: FormData; client?: Client },
  config?: AxiosRequestConfig,
): Promise<ClientResponse> => {
  const { data } = await http
    .post<FormData, { data: ClientResponse }>(`${ClientsUrl}/${client?.id}/avatar`, avatar, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);

  return { data };
};

const clientsAdapter = createEntityAdapter<Client>();
const initialState = clientsAdapter.getInitialState();

export const clientApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllClients: build.query<Record<string, Client>, void>({
      query: () => ({ url: ClientsUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Clients, { type: TagType.Clients, id: `${TagType.Clients}` }]
          : [{ type: TagType.Clients, id: `${TagType.Clients}-LIST` }];
      },
      transformResponse: (response: Client[]) => {
        const { entities } = clientsAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getClient: build.query<Client, string>({
      query: (clientId: string) => ({ method: 'GET', url: `/${ClientsUrl}/${clientId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Clients, id }],
      extraOptions: { maxRetries: 2 },
    }),
    getClientInvoicesSummary: build.query<TotalSummary[], { clientId: string | undefined; filter: TODO }>({
      query: ({ clientId, filter }) => ({
        method: 'GET',
        url: `/${ClientsUrl}/${clientId}/invoices-summary`,
        params: filter,
      }),
      providesTags: (_result, _error, { clientId, filter }) => [
        { type: TagType.Clients, clientId, id: 'invoicesSummary', filter },
      ],
    }),
    getClientExpensesSummary: build.query<TotalSummary[], { clientId: string | undefined; filter: TODO }>({
      query: ({ clientId, filter }) => ({
        method: 'GET',
        url: `/${ClientsUrl}/${clientId}/expenses-summary`,
        params: filter,
      }),
      providesTags: (_result, _error, { clientId, filter }) => [
        { type: TagType.Clients, clientId, id: 'expensesSummary', filter },
      ],
    }),
    createClient: build.mutation<Client, Client>({
      query: (client: Client) => ({ method: 'POST', url: ClientsUrl, data: client }),
      invalidatesTags: () => [{ type: TagType.Clients }, { type: TagType.DashboardCounters }],
      extraOptions: { maxRetries: 0 },
    }),
    updateClient: build.mutation<Client, Client>({
      query: (client: Client) => ({ method: 'PUT', url: `/${ClientsUrl}/${client.id}`, data: client }),
      invalidatesTags: (_result, _error, client) => [
        { type: TagType.Clients, id: client.id },
        { type: TagType.Clients },
      ],
    }),
    deleteClient: build.mutation<Client, string>({
      query: (clientId: string) => ({ method: 'DELETE', url: `/${ClientsUrl}/${clientId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Clients, id: `${TagType.Clients}` },
      ],
    }),
  }),
});

export const clientsLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(clientApi.endpoints.getAllClients.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const clientLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(clientApi.endpoints.getClient.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const initiateClientRequest = (id: string) => {
  const promise = store.dispatch(clientApi.endpoints.getClient.initiate(id));
  promise.unsubscribe();
  return promise;
};

export const initiateAllClientsRequest = () => {
  const promise = store.dispatch(clientApi.endpoints.getAllClients.initiate());
  promise.unsubscribe();
  return promise;
};

export const {
  useGetAllClientsQuery,
  useGetClientQuery,
  useGetClientInvoicesSummaryQuery,
  useGetClientExpensesSummaryQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} = clientApi;
