import { createReducer } from '@reduxjs/toolkit';
import { toggleMobileSidebar, toggleSidebar } from './sidebar.actions';
import { SidebarState } from './sidebar.types';

const getCollapsedFromLocalStorage = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  const collapsed = window.localStorage.getItem('brojki:sidebarCollapsed');
  return collapsed != null ? JSON.parse(collapsed) : false;
};

const initialState: SidebarState = {
  isCollapsed: getCollapsedFromLocalStorage(),
  isOpen: false,
};

export const sidebarReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleSidebar, (state, action) => {
      return {
        ...state,
        isCollapsed: action?.payload,
      };
    })
    .addCase(toggleMobileSidebar, (state, action) => {
      return {
        ...state,
        isOpen: action?.payload,
      };
    });
});
