import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { Provider } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';
import { ThemedApp } from './App';
import { store } from './Core/AppStore';
import { DashboardLoadingState } from './Core/components/Loaders/DashboardLoadingState.tsx';
import { SplitViewLoadingState } from './Core/components/Loaders/SplitViewLoadingState';
import TableSkeletonLoader from './Core/components/Loaders/TableSkeletonLoader.tsx';
import { SideBar } from './Sidebar/components/SideBar';
import { lazyRetry } from './lazyRetry';

export const RouteNotFound = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "RouteNotFound" */ './Core/components/EmptyStates/RouteNotFound'),
    'RouteNotFound',
  ),
);

export const ErrorBoundary = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "ErrorBoundary" */ './Core/ErrorBoundary/ErrorBoundary'),
    'ErrorBoundary',
  ),
);

const AppContainerFallback: React.FC = () => {
  const matchesDashboard = useMatch('/dashboard/*');
  const matchesCompany = useMatch('/company/*');
  const matchesProfile = useMatch('/profile/*');

  const matchesNonSplitView = React.useMemo(
    () => matchesDashboard || matchesCompany || matchesProfile,
    [matchesCompany, matchesDashboard, matchesProfile],
  );
  const loadingState = isMobile ? <SplitViewLoadingState /> : <TableSkeletonLoader />;

  return matchesNonSplitView != null ? <DashboardLoadingState /> : loadingState;
};

export const AppContainer: React.FC = () => {
  return (
    <Box
      data-testid="app-container"
      position="relative"
      display="flex"
      flex="1"
      flexDir="row"
      width="100%"
      height="100%"
      overflowY="auto"
      overflowX="hidden"
    >
      <HotkeysProvider>
        <SideBar />
        <React.Suspense fallback={<AppContainerFallback />}>
          <ErrorBoundary>
            <Box as="section" data-testid="main-section">
              {<Outlet />}
            </Box>
          </ErrorBoundary>
        </React.Suspense>
      </HotkeysProvider>
    </Box>
  );
};

export const Root: React.FC = () => (
  <Provider store={store}>
    <ThemedApp />
  </Provider>
);
