import { isMobile } from 'react-device-detect';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { LoadingBrojki } from './Core/components/BrojkiLogo/LoadingBrojki.tsx';
import { desktopRouter } from './routes/desktopRoutes.tsx';
import { mobileRouter } from './routes/mobileRoutes.tsx';
import { setupSentry } from './setupSentry';

import './index.scss';
setupSentry();

const container = document.getElementById('root');

const root = createRoot(container!);

const Index: React.FC = () => {
  const mainRouter = isMobile ? mobileRouter : desktopRouter;
  return (
    <RouterProvider future={{ v7_startTransition: true }} router={mainRouter} fallbackElement={<LoadingBrojki />} />
  );
};

root.render(<Index />);
