import { Box, Skeleton, SkeletonText, Stack, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import * as React from 'react';
import { GridCard, GridPage } from '../ContentContainers/GridPage.tsx';

interface Props {
  rows?: number;
  columns?: number;
}

const TableSkeletonLoader: React.FC<Props> = ({ rows = 5, columns = 3 }) => {
  return (
    <GridPage title="">
      <GridCard title="">
        <Box p={4} overflow="hidden">
          {/* Filters Section */}
          <Stack direction="row" spacing={4} mb={4} alignItems="flex-end" justifyContent="flex-end">
            <Skeleton height="20px" width="100px" />
            <Skeleton height="20px" width="100px" />
            <Skeleton height="20px" width="100px" />
          </Stack>

          {/* Chart Section */}
          <Skeleton height="300px" />

          {/* Additional Text or Info */}
          <SkeletonText mt="4" noOfLines={2} spacing="4" />
        </Box>
      </GridCard>
      <GridCard title="">
        <Box p={4} overflow="hidden">
          <Stack direction="row" spacing={4}>
            {/* Skeleton for the profile image */}
            <Skeleton boxSize="150px" borderRadius="md" />

            <Stack flex={1} spacing={3}>
              {/* Skeletons for the text lines */}
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />

              {/* Skeletons for action buttons */}
              <Stack direction="row" spacing={2}>
                <Skeleton boxSize="30px" borderRadius="md" />
                <Skeleton boxSize="30px" borderRadius="md" />
                <Skeleton boxSize="30px" borderRadius="md" />
                <Skeleton boxSize="30px" borderRadius="md" />
                <Skeleton boxSize="30px" borderRadius="md" />
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </GridCard>
      <GridCard title="">
        <Table w="full">
          <Thead>
            <Tr>
              {[...Array(columns)].map((_, colIndex) => (
                <Th key={colIndex} p={4}>
                  <Skeleton height="20px" />
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {[...Array(rows)].map((_, rowIndex) => (
              <Tr key={rowIndex}>
                {[...Array(columns)].map((_, colIndex) => (
                  <Td key={colIndex} p={4}>
                    <Skeleton height="20px" />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </GridCard>
    </GridPage>
  );
};

export default TableSkeletonLoader;
