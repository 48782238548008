import { SerializedError } from '@reduxjs/toolkit';
import { standaloneToast as toaster } from '../../Toaster/components/Toaster';
import { createErrorToast, createInfoToast } from '../../Toaster/store/toaster.utils';
import { Client, ClientType } from './clients.types';

export const isClientCompany = (client?: Client | null): boolean => {
  return client?.type === ClientType.Company;
};

export const isClientIndividual = (client?: Client | null): boolean => {
  return client?.type === ClientType.Individual;
};

type Error =
  | {
      status: number;
      data: {
        message?: string | undefined;
        error?: string | undefined;
        errorCode?: string | undefined;
      };
    }
  | SerializedError;

type Result =
  | {
      data: Client;
    }
  | {
      error: Error;
    };

export const notifyClientDeleted = (client: Client, result: Result) => {
  let toast;
  if ('error' in result) {
    toast = createErrorToast({
      id: `${client.id}-error`,
      title: 'Failed to delete client',
    });

    toaster(toast);
    return false;
  }

  if ('data' in result && result.data.id === client.id) {
    toast = createInfoToast({
      id: `${client.id}-success`,
      title: 'Успешно',
      description: `Клиентот "${client?.businessName ?? client?.fullName}" е додаден во корпа.`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const notifyClientUpdated = (client: Client, result: Result) => {
  if ('error' in result) {
    const toast = createErrorToast({
      id: `${client.id}-error`,
      title: 'Failed to update client',
    });

    toaster(toast);

    return false;
  }

  if ('data' in result && result.data.id === client.id) {
    const toast = createInfoToast({
      id: `${client.id}-success`,
      title: 'Успешно',
      description: `Клиентот "${client?.businessName ?? client?.fullName}" e зачуван!`,
    });
    toaster(toast);

    return result.data;
  }

  return false;
};

export const redirectClientAfterDeletion = (client: Client, clients?: Record<string, Client>) => {
  if (!clients) {
    return null;
  }

  const { [client.id]: _, ...withoutRemoved } = clients;
  const deletedIndex = Object.keys(clients).findIndex((i) => clients[i]?.id === client.id);
  const redirectTo = withoutRemoved[Object.keys(withoutRemoved)[deletedIndex]];

  return redirectTo;
};
