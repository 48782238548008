import { DraftableStatus } from '../../Core/core.types';
import { Creatable } from '../../Core/utils/standard.model';
import { Currency } from '../../Treasuries/store/treasuries.utils';

export enum ProductType {
  Product = 'Product',
  Service = 'Service',
}

export enum ProductUnit {
  Unspecified = '-',
  Piece = 'парче',
  Hour = 'час',
  Day = 'ден',
  Month = 'месец',
  Kilogram = 'kg',
  Gram = 'g',
  Liter = 'L',
  Meter = 'm',
  Centimeter = 'cm',
  Millimeter = 'mm',
  MeterSquared = 'm²',
  MeterCubic = 'm³',
  Kilometer = 'km',
  Hectar = 'ha',
  Ton = 't',
  Words = 'зборови',
  Year = 'години',
  Week = 'недели',
  Minute = 'минути',
  Megabyte = 'MB',
  Gigabyte = 'GB',
}

export interface Product extends Creatable {
  id: string;
  name?: string;
  description?: string;
  currency?: Currency;
  unit?: string | ProductUnit;
  unitPrice?: number;
  unitCost: string;
  type: ProductType;
  status: DraftableStatus;
  inStock?: boolean;
  amountInStock: string;
  skuCode?: string;
  itemWidth?: string;
  itemHeight?: string;
  itemDepth?: string;
  itemLength?: string;
  itemWeight?: string;
  itemBrand?: string;
  itemColor?: string;
  itemMaterial?: string;
  notes?: string;
}
