import { Avatar, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Client } from '../../Clients/api/clients.types';
import { isClientCompany, isClientIndividual } from '../../Clients/api/clients.utils';
interface Props {
  client: Client;
  query?: string;
}
export const SpotlightClientDetail: React.FC<Props> = ({ client }) => {
  const {
    fullName,
    avatarUrl,
    contactAddress,
    contactPhone,
    contactEmail,
    website,
    businessName,
    businessAddress,
    businessBankAccount,
    businessId,
    businessVatId,
    businessEmail,
  } = client;

  const name = isClientCompany(client) ? businessName : fullName;

  return client ? (
    <div className="d-flex flex-column justify-content-center">
      <div className="d-flex mb-4 align-items-center">
        <Avatar name={name} src={avatarUrl} size="md" />
        <h5 className="h5 mb-0 ps-2">{name}</h5>
      </div>
      {isClientIndividual(client) ? (
        <div className="d-flex flex-column">
          <h4 className="h4 mb-2">{client.fullName}</h4>
          <Text className="text-muted">Адреса</Text>
          <Text className="mt-0 mb-1">{contactAddress}</Text>
          <Text className="text-muted">Телефон</Text>
          <Text className="mt-0  mb-1">{contactPhone}</Text>
          <Text className="text-muted">Емаил</Text>
          <Text className="mt-0 mb-1">
            <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
          </Text>
          <Text className="text-muted">Wеб</Text>
          {website ? (
            <Text className="mt-0">
              <a href={website} target="_blank noreferrer" rel="noopener">
                {website}
              </a>
            </Text>
          ) : null}
        </div>
      ) : null}

      {isClientCompany(client) && (
        <div className="d-flex flex-column">
          <h4 className="h4 mb-2">{businessName}</h4>
          <Text className="text-muted">Адреса</Text>
          <Text className="mt-0 mb-2">{businessAddress}</Text>
          <Text className="text-muted">ЕМБС (Единствен Матичен Број на Субјектот)</Text>
          <Text className="mb-2">{businessId}</Text>
          <Text className="text-muted">ЕДБ (Единствен Даночен Број)</Text>
          <Text className="mb-2">{businessVatId}</Text>
          <Text className="text-muted">Ж.Сметка</Text>
          <Text className="mb-2">{businessBankAccount}</Text>
          <Text className="mt-0">
            <a href={`mailto:${businessEmail}`}>{businessEmail}</a>
          </Text>

          <Text className="my-2 mt-4 text-muted">Контакт лице</Text>
          <h4 className="h4 mb-2">{client.fullName}</h4>
          <Text className="text-muted">Адреса</Text>
          <Text className="mt-0 mb-2">{contactAddress}</Text>
          <Text className="text-muted">Телефон</Text>
          <Text className="mt-0">{contactPhone}</Text>
        </div>
      )}
    </div>
  ) : null;
};
SpotlightClientDetail.displayName = 'SpotlightClientDetail';
