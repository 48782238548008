import { ClientType } from '../../Clients/api/clients.types';
import { ProductType } from '../../Products/api/products.types';
import { TreasuryType } from '../../Treasuries/store/treasuries.types';
import { PaymentMethod } from './payment-methods';

type TTypes = { [key in keyof typeof TreasuryType]: string };

export const getTranslatedTreasuryType = (type: TreasuryType): string | undefined => {
  // NOTE: Quote and Offer are the 'identical' due to historical reasons.
  // NOTE: Estimate and Proforma are 'identical' due to historical reasons.
  const types: Partial<TTypes> = {
    [TreasuryType.Invoice]: 'Фактура',
    [TreasuryType.Quote]: 'Понуда',
    [TreasuryType.Offer]: 'Понуда',
    [TreasuryType.Estimate]: 'Про Фактура',
    [TreasuryType.ProForma]: 'Про Фактура',
  };

  return types[type];
};

export const getTranslatedProductType = (type: ProductType) => {
  const types = {
    [ProductType.Product]: 'Производ',
    [ProductType.Service]: 'Услуга',
  };

  return types[type];
};

export const getTranslatedClientType = (type: ClientType) => {
  const types = {
    [ClientType.Company]: 'Компанија',
    [ClientType.Individual]: 'Физичко Лице',
  };

  return types[type];
};

export const getTranslatedPaymentMethod = (paymentMethod?: PaymentMethod): string => {
  const methods = {
    [PaymentMethod.Cash]: 'Сметка',
    [PaymentMethod.Invoice]: 'Фактура',
    [PaymentMethod.BankTransfer]: 'Банкарски \nTрансфер',
    [PaymentMethod.Cheque]: 'Чек',
    [PaymentMethod.CreditCard]: 'Кредитна \nКартичка',
    [PaymentMethod.DebitCard]: 'Дебитна \nКартичка',
    [PaymentMethod.PayPal]: 'PayPal',
    [PaymentMethod.MasterCard]: 'Master \nCard',
    [PaymentMethod.Visa]: 'Visa',
    [PaymentMethod.DinersClub]: 'Diners \nClub',
    [PaymentMethod.AMEX]: 'AMEX',
  };

  return paymentMethod ? methods[paymentMethod] : '';
};
