import * as React from 'react';
import styles from './BrojkiLogo.module.scss';

export const LoadingBrojki: React.FC = () => {
  return (
    <div
      className={styles['app-logo']}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <svg className={styles['svg-logo']} viewBox="0 0 640 402" width="200">
        <g>
          <path
            className={styles['logo-p-shape']}
            d="M0,66.0321027 L0,402 L640,402 L640,338.333868 L140.094149,338.333868 C118.359112,338.333868 100.067249,322.417335 96.8392737,300.908507 C87.1553463,235.091493 68.00269,105.608347 68.00269,105.608347 L217.565568,105.608347 L217.565568,234.016051 L281.479489,234.016051 L281.479489,66.0321027 C281.479489,66.0321027 0,66.247191 0,66.0321027 L0,66.0321027 Z"
            fill="#0052CC"
          ></path>
        </g>
        <g>
          <path
            className={styles['logo-s-shape']}
            d="M174.095494,131.418941 L174.095494,273.807384 L324.734364,273.807384 L324.734364,0 L640,0 L640,313.383628 L537.780767,313.383628 C537.780767,313.383628 558.870208,169.919743 570.490921,91.4125201 C572.427707,78.7223114 568.554136,66.0321027 560.376597,56.35313 C551.98386,46.6741573 539.932751,41.081862 527.020847,41.081862 L422.219233,41.081862 L382.192334,313.168539 L135.574983,313.168539 L108.890383,131.418941 L174.095494,131.418941 L174.095494,131.418941 Z"
            fill="#8cc7fc"
          ></path>
        </g>
      </svg>
      <h2 className="h2">Loading Brojki</h2>
      <h4 className="h4">Ве молиме почекајте...</h4>
    </div>
  );
};
