import { Text } from '@chakra-ui/react';
import * as React from 'react';
import { CashPayment } from '../../CashPayments/api/payments.types';
import { isClientCompany, isClientIndividual } from '../../Clients/api/clients.utils';
import { toFormattedDate } from '../../Core/utils/dateTime';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';

interface Props {
  cashPayment: CashPayment;
  query?: string;
}

export const SpotlightCashPaymentDetail: React.FC<Props> = ({ cashPayment }) => {
  const { client } = cashPayment;

  return cashPayment ? (
    <div className="d-flex flex-column justify-content-center">
      <h6 className="h6 text-center">Каса Прими - Бр. {cashPayment.referenceNumber}</h6>
      <Text>
        <strong>Датум на сметка:</strong> {cashPayment.paymentDate ? toFormattedDate(cashPayment.paymentDate) : null}
      </Text>
      <Text>
        <strong>Белешки: </strong>
        {cashPayment.notes}
      </Text>
      <div>
        <div className="d-flex w-100 mt-5 mb-3 flex-column justify-content-end">
          <h6 className="h6 d-flex justify-content-between mt-2 mb-2">
            <strong>Износ</strong> <span>{formatMoney(parseFloat(cashPayment.amount), cashPayment.currency)}</span>
          </h6>
        </div>
      </div>
      <div className="d-flex text-left client-info mt-2">
        <address>
          {isClientIndividual(client) ? (
            <>
              <Text>
                <strong>Од: </strong>
                {client?.fullName}
              </Text>
              <Text>{client?.contactAddress}</Text>
              <Text>{client?.contactEmail} </Text>
            </>
          ) : null}
          {isClientCompany(client) ? (
            <>
              <Text>
                <strong>Од: </strong>
                {client?.businessName}
              </Text>
              <Text>{client?.businessAddress}</Text>
              <Text>{client?.businessEmail} </Text>
            </>
          ) : null}
        </address>
      </div>
    </div>
  ) : null;
};
SpotlightCashPaymentDetail.displayName = 'SpotlightCashPaymentDetail';
