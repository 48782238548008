import {
  Box,
  Button,
  Link as ChakraLink,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { useAppDispatch, useAppSelector } from '../../../Core/AppStore';
import { validate } from '../../../Core/utils/validate';
import { captureEvent } from '../../../setupPostHog';
import { login } from '../../store/login.actions';
import { AuthenticationPage } from './AuthenticationPage';

const validationSchema = validate.object().shape({
  email: validate.string().required('Потребна е емаил адреса').email('Неважечка емаил адреса'),
  password: validate.string().required('Потребна е лозинка'),
  remember: validate.boolean(),
});

interface FormValues {
  email: string;
  password: string;
  remember?: boolean;
}

const resolver = yupResolver(validationSchema);

export const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(({ authenticated }) => authenticated.isLoading);

  const colorScheme = useAppSelector((state) => state.ui.theme.colorScheme);
  const inputFocusBorderColor = useAppSelector((state) => state.ui.theme.inputFocusBorderColor);

  const form = useForm<FormValues>({
    resolver,
    defaultValues: { email: '', password: '', remember: false },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = form;

  const isEmailInvalid = !!(errors.email && touchedFields.email);
  const isPasswordInvalid = !!(errors.password && touchedFields.password);
  const remember = form.watch('remember');
  const [, setValue] = useLocalStorage('brojki:remember', remember);

  React.useEffect(() => {
    setValue(remember);
  }, [remember, setValue]);

  const onSubmit = async ({ email, password, remember }: FormValues) => {
    captureEvent('user_login');
    await dispatch(login(email, password, remember));
  };

  return (
    <AuthenticationPage showProgress={isLoading}>
      <div className="container mt-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl mb={3} isInvalid={isEmailInvalid}>
            <FormLabel htmlFor="email">Емаил</FormLabel>
            <Input
              tabIndex={1}
              id="email"
              type="email"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.email ? 'Внеси ја твојата емаил адреса' : ''}
              {...register('email')}
            />
            <FormErrorMessage>
              <>{errors.email?.message}</>
            </FormErrorMessage>
          </FormControl>

          <FormControl mb={3} isInvalid={isPasswordInvalid}>
            <FormLabel htmlFor="password">Лозинка</FormLabel>
            <Input
              tabIndex={2}
              className="mb-2"
              type="password"
              id="password"
              autoComplete="on"
              focusBorderColor={inputFocusBorderColor}
              placeholder={touchedFields.password ? 'Внеси ја твојата лозинка' : ''}
              {...register('password')}
            />
            <FormErrorMessage>
              <>{errors.password?.message}</>
            </FormErrorMessage>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Checkbox id="remember" colorScheme={colorScheme} {...register('remember')} tabIndex={4}>
                Запамти ме
              </Checkbox>
              <ChakraLink
                tabIndex={5}
                href="https://brojki.com/password/reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ја заборави лозинката?
              </ChakraLink>
            </Box>
          </FormControl>

          <Box width="100%" display="flex">
            <Button
              id="submit"
              width="100%"
              isDisabled={isLoading}
              mt={4}
              colorScheme={colorScheme}
              tabIndex={3}
              isLoading={isLoading}
              type="submit"
            >
              Најави се
            </Button>
          </Box>
        </form>
        <Box mt={4} mb={4} ml={2}>
          <Link to="/signup">Не сте корисник? Регистрирај се</Link>
        </Box>
      </div>
    </AuthenticationPage>
  );
};
export default LoginPage;
