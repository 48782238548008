import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { TODO } from '../../Core/core.types';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';

const {
  DashboardCountersUrl,
  DashboardInvoicesUrl,
  DashboardExpensesUrl,
  DashboardPaymentsUrl,
  DashboardTopCategoriesUrl,
  DashboardTopClientsUrl,
} = ApiEndpoints;

export interface DashboardFilters {
  client?: string; // client id
  q?: string;
}

export const dashboardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDashboardCounters: build.query<TODO, void>({
      query: () => ({ url: DashboardCountersUrl, method: 'GET' }),
      providesTags: () => [TagType.DashboardCounters],
    }),
    getDashboardInvoices: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardInvoicesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardInvoicesChart],
    }),
    getDashboardExpenses: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardExpensesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardExpensesChart],
    }),
    getDashboardPayments: build.query<TODO, DashboardFilters>({
      query: (filter) => ({ url: DashboardPaymentsUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardPaymentsChart],
    }),
    getDashboardTopClients: build.query<TODO, { year: number }>({
      query: (filter) => ({ url: DashboardTopClientsUrl, method: 'GET', params: filter }),
      providesTags: () => [TagType.DashboardTopClientsChart],
    }),
    getDashboardTopCategories: build.query<TODO, { year: number }>({
      query: (filter) => ({ url: DashboardTopCategoriesUrl, params: filter, method: 'GET' }),
      providesTags: () => [TagType.DashboardTopCategoriesChart],
    }),
  }),
});

export const dashboardCountersLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(dashboardApi.endpoints.getDashboardCounters.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  return promise;
};

export const {
  useGetDashboardCountersQuery,
  useGetDashboardInvoicesQuery,
  useGetDashboardExpensesQuery,
  useGetDashboardPaymentsQuery,
  useGetDashboardTopClientsQuery,
  useGetDashboardTopCategoriesQuery,
} = dashboardApi;
