import { useAppSelector } from '../AppStore';
import { getCurrentUserFromLocalStorage } from './getCurrentUserFromLocalStorage';

export enum Feature {
  ViewPaymentsModule = 'viewPaymentsModule',
  ViewNewTopCharts = 'viewNewTopCharts',
  ViewAudits = 'viewAudits',
}
export const useShouldEnableFeature = (feature: Feature): [boolean] => {
  const user = useAppSelector((state) => state.authenticated.user);
  const [currentUser] = getCurrentUserFromLocalStorage();

  if (currentUser?.can) {
    return [currentUser.can[feature] ?? false];
  }

  if (user?.can) {
    return [user.can[feature] ?? false];
  }

  return [false];
};
