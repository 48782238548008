import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  faAddressBook,
  faCalculatorSimple,
  faCashRegister,
  faChartUser,
  faCreditCardFront,
  faFileInvoice,
  faFlask,
  faRightFromBracket,
  faScannerKeyboard,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../Core/AppStore';
import { authService } from '../../Core/auth/authService';
import { BrojkiLogo } from '../../Core/components/BrojkiLogo/BrojkiLogo';
import { Feature, useShouldEnableFeature } from '../../Core/utils/shouldEnableFeature';
import { toggleMobileSidebar } from '../store/sidebar.actions';
import { MenuItemButton } from './MenuItemButton';
import { MenuItemLink } from './MenuItemLink';
import './SideBar.scss';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
}

export const MobileSideBar: React.FC<React.PropsWithChildren<Props>> = ({ children, isOpen }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);
  const theme = useAppSelector(({ ui }) => ui.theme.name);
  const sidebarClassNames = clsx('Sidebar', theme, 'd-print-none');
  const close = () => dispatch(toggleMobileSidebar(false));

  return (
    <Drawer placement="left" isOpen={isOpen} onClose={close} size="xs">
      <DrawerOverlay>
        <DrawerContent background="#f8f9fc">
          <DrawerCloseButton color="white" onClick={close} />
          <DrawerHeader>
            <BrojkiLogo pShapeColor="#333" sShapeColor="#333" textColor="#333" showLogoText />
          </DrawerHeader>

          <DrawerBody padding={0} margin={0}>
            <Box className={sidebarClassNames} width="100%" boxShadow="none" padding="0" margin="0">
              <nav className="Sidebar-menu d-print-none">
                <ul style={{ paddingLeft: 0 }}>
                  <MenuItemLink
                    to="/dashboard"
                    icon={<FontAwesomeIcon icon={faChartUser} />}
                    label="Инфо Панел"
                    onClick={close}
                  >
                    Инфо Панел
                  </MenuItemLink>
                  <MenuItemLink
                    to="/invoices"
                    icon={<FontAwesomeIcon icon={faFileInvoice} />}
                    label="Фактури"
                    onClick={close}
                  >
                    Фактури
                  </MenuItemLink>
                  <MenuItemLink
                    to="/estimates"
                    icon={<FontAwesomeIcon icon={faCalculatorSimple} />}
                    label="Пресметки"
                    onClick={close}
                  >
                    Пресметки
                  </MenuItemLink>
                  <MenuItemLink
                    to="/expenses"
                    icon={<FontAwesomeIcon icon={faCreditCardFront} />}
                    label="Расходи"
                    onClick={close}
                  >
                    Расходи
                  </MenuItemLink>
                  {shouldEnablePayments ? (
                    <MenuItemLink
                      to="/cash-payments"
                      icon={<FontAwesomeIcon icon={faCashRegister} />}
                      label="Каса Прими"
                      onClick={close}
                    >
                      Каса Прими
                    </MenuItemLink>
                  ) : null}
                  <MenuItemLink
                    to="/clients"
                    icon={<FontAwesomeIcon icon={faAddressBook} />}
                    label="Клиенти"
                    onClick={close}
                  >
                    Клиенти
                  </MenuItemLink>
                  <MenuItemLink
                    to="/projects"
                    icon={<FontAwesomeIcon icon={faFlask} />}
                    label="Проекти"
                    onClick={close}
                  >
                    Проекти
                  </MenuItemLink>
                  <MenuItemLink
                    to="/products"
                    icon={<FontAwesomeIcon icon={faScannerKeyboard} />}
                    label="Производи и Услуги"
                    onClick={close}
                  >
                    Производи и Услуги
                  </MenuItemLink>
                  <Divider mb={1} mt={1} borderColor="#e3e8ef" />
                  {children}
                  <Divider mb={1} mt={1} borderColor="#e3e8ef" />
                  <MenuItemButton
                    label="Одјавете се"
                    isCollapsed={false}
                    icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                    onClick={() => {
                      toast({
                        title: 'Одјавување!',
                        description: 'Одјавување од апликацијата...',
                        status: 'info',
                        position: 'top-right',
                        duration: 2000,
                        isClosable: true,
                      });
                      setTimeout(() => authService.logout(), 456);
                    }}
                  >
                    Одјавете се
                  </MenuItemButton>
                </ul>
              </nav>
            </Box>
          </DrawerBody>

          {/* <DrawerFooter>
            <Button
              leftIcon={<MdExitToApp />}
              variant="outline"
              colorScheme="gray"
              color="white"
              mr={3}
              onClick={() => {
                toast({
                  title: 'Одјавување!',
                  description: 'Одјавување од системот...',
                  status: 'info',
                  position: 'top-right',
                  duration: 2000,
                  isClosable: true,
                });
                setTimeout(() => authService.logout(), 456);
              }}
            >
              Одјавете се
            </Button>
          </DrawerFooter> */}
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
