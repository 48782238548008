import { Box, Divider, Heading, Text } from '@chakra-ui/react';
import * as React from 'react';
import { toFormattedDate } from '../../Core/utils/dateTime';
import { Invoice } from '../../Invoices/api/invoices.types';
import { getTreasuryType } from '../../Treasuries/store/treasuries.types';
import {
  formatMoney,
  getLineTotalAmount,
  getTreasurySubTotal,
  getTreasuryTotalAmount,
  getTreasuryTotalTaxAmount,
} from '../../Treasuries/store/treasuries.utils';

interface Props {
  invoice: Invoice;
  query?: string;
}

export const SpotlightInvoiceDetail: React.FC<Props> = ({ invoice }) => {
  return invoice ? (
    <Box className="d-flex flex-column justify-content-center">
      <Heading as="h6" fontSize="xl" className="text-center">
        {getTreasuryType(invoice)} Бр. {invoice.invoiceNumber}
      </Heading>

      <Box display="flex" mt={4} justifyContent="space-between">
        <Box width="50%" display="flex" flexDir="column">
          <Text color="gray.500" className="mb-1" fontSize="md">
            Клиент
          </Text>
          <Text className="mb-0">{invoice.client && invoice.client.fullName}</Text>
          <Text className="mb-1 fw-light">{invoice.client && invoice.client.businessName}</Text>
          <Text>T: {invoice.client && invoice.client.contactPhone} </Text>
        </Box>
        <Divider orientation="vertical" />
        <Box width="50%" display="flex" flexDir="column" alignItems="flex-end">
          <Text color="gray.500" className="mb-1" fontSize="md">
            Издадена
          </Text>
          <Text>
            <>{invoice.invoicedDate ? toFormattedDate(invoice.invoicedDate) : null}</>
          </Text>
        </Box>
      </Box>
      <Box mt={4}>
        <Divider bgColor="gray.300" />
        {invoice.lineItems.map((item) => (
          <Box key={item.id} display="flex" justifyContent="space-between" my={2}>
            <Text fontSize="sm">{item.product?.name}</Text>
            <Text>{formatMoney(getLineTotalAmount(item), invoice.currency)}</Text>
          </Box>
        ))}
      </Box>
      <Divider bgColor="gray.300" my={2} />
      <Box mt={2}>
        <Text className="d-flex justify-content-between mb-1">
          <strong className="text-start">Износ без ДДВ</strong>
          <span className="text-end">{formatMoney(getTreasurySubTotal(invoice))}</span>
        </Text>
        <Text className="d-flex justify-content-between mb-1">
          <strong>Износ на ДДВ</strong>
          <span className="text-end">{formatMoney(getTreasuryTotalTaxAmount(invoice))}</span>
        </Text>
        <Text className="d-flex justify-content-between mb-1">
          <strong>Вкупно</strong>
          <span className="text-end">{formatMoney(getTreasuryTotalAmount(invoice))}</span>
        </Text>
      </Box>
    </Box>
  ) : null;
};
SpotlightInvoiceDetail.displayName = 'SpotlightInvoiceDetail';
