import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { faMagnifyingGlass, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useSearchParams } from '../../hooks/useSearchParams';
import './MasterViewToolbar.scss';

interface ToolbarActionProps {
  onClick?: () => void;
  icon?: React.ReactElement;
  label: string;
  tooltipBg?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  // Used to create entities
  isCreator?: boolean;
}
export const ToolbarAction: React.FC<React.PropsWithChildren<ToolbarActionProps>> = ({
  icon = <FontAwesomeIcon icon={faPlus} />,
  label,
  onClick,
  tooltipBg = 'gray.700',
  isCreator = false,
  isActive = false,
  isDisabled = false,
  isLoading = false,
  children,
}) => {
  const hasChildren = React.Children.count(children) > 0;

  const defaultProps = {
    ml: isCreator ? 'auto' : 1,
    mr: isCreator ? 4 : 'auto',
    width: isCreator ? '50px' : '1.5rem',
    color: isActive ? 'sky.500' : 'gray.600',
    variant: isCreator ? 'solid' : 'ghost',
    colorScheme: isCreator ? 'sky' : 'gray',
    textColor: isCreator ? 'white' : 'gray.600',
    size: 'sm',
    'aria-label': label,
    fontSize: '24px',
    isLoading,
    isDisabled,
    icon,
  };

  return hasChildren ? (
    <Menu>
      <Tooltip label={label} placement="bottom" zIndex={999} bg={tooltipBg} color="white">
        <MenuButton as={IconButton} onClick={onClick} {...defaultProps} />
      </Tooltip>
      <MenuList>{children}</MenuList>
    </Menu>
  ) : (
    <Tooltip hasArrow aria-label={label} label={label} placement="bottom" zIndex={999} bg={tooltipBg} color="white">
      <IconButton onClick={onClick} {...defaultProps} />
    </Tooltip>
  );
};
ToolbarAction.displayName = 'ToolbarAction';

interface ToolbarSearchProps {
  isDisabled?: boolean;
}
const ToolbarSearch: React.FC<React.PropsWithChildren<ToolbarSearchProps>> = ({ isDisabled = false }) => {
  const { searchParams, setSearchParams } = useSearchParams();
  const [searchTerm, setSearchTerm] = React.useState<string>(searchParams.get('query') || '');

  React.useEffect(() => {
    if (!searchTerm) {
      setSearchParams((params) => {
        searchParams.delete('query');
        return params;
      });
    } else {
      searchParams.set('query', searchTerm);
      setSearchParams(searchParams);
    }
  }, [searchParams, searchTerm, setSearchParams]);

  return (
    <div className="toolbar-search w-100">
      <InputGroup size="sm">
        <InputLeftElement color="gray.400" children={<FontAwesomeIcon icon={faMagnifyingGlass} size="lg" />} />
        <Input
          id="query"
          name="query"
          type="text"
          placeholder="Барај"
          size="sm"
          defaultValue={searchTerm}
          isDisabled={isDisabled}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
      </InputGroup>
    </div>
  );
};

interface MasterViewToolbarProps {
  entity?: 'Invoice' | 'Estimate' | 'Client' | 'Expense' | 'CashPayment' | 'Project' | 'Product' | 'None';
  isDisabled?: boolean;
}
export const MasterViewToolbar: React.FC<React.PropsWithChildren<MasterViewToolbarProps>> = ({
  children,
  isDisabled = false,
}) => {
  return (
    <Box as="nav" py={1} className="nav nav-toolbar nav-justified flex-sm-row align-items-center justify-content-start">
      <Box display="flex" width="100%" ml={2} py={1}>
        {children}
      </Box>
      <ToolbarSearch isDisabled={isDisabled} />
    </Box>
  );
};
