import { Text } from '@chakra-ui/react';
import * as React from 'react';
import { isClientCompany, isClientIndividual } from '../../Clients/api/clients.utils';
import { toFormattedDate } from '../../Core/utils/dateTime';
import { Expense } from '../../Expenses/api/expenses.types';
import { formatMoney } from '../../Treasuries/store/treasuries.utils';

interface Props {
  expense: Expense;
  query?: string;
}

export const SpotlightExpenseDetail: React.FC<Props> = ({ expense }) => {
  const { client } = expense;

  return expense ? (
    <div className="d-flex flex-column justify-content-center">
      <h6 className="h6 text-center">Трошок - Бр. {expense.expenseNumber}</h6>
      <Text>
        <strong>Датум на исплата:</strong> {expense.expenseDate ? toFormattedDate(expense.expenseDate) : null}
      </Text>
      <Text>
        <strong>Белешки: </strong>
        {expense.notes}
      </Text>
      <div>
        <div className="d-flex w-100 mt-5 mb-3 flex-column justify-content-end">
          <h6 className="h6 d-flex justify-content-between mt-2 mb-2">
            <strong>Износ </strong> <span>{formatMoney(parseFloat(expense.amount), expense.currency)}</span>
          </h6>
        </div>
      </div>
      <div className="d-flex text-left client-info mt-2">
        <address>
          {isClientIndividual(client) ? (
            <>
              <Text>
                <strong>До: </strong>
                {client?.fullName}
              </Text>
              <Text>{client?.contactAddress}</Text>
              <Text>{client?.contactEmail} </Text>
            </>
          ) : null}
          {isClientCompany(client) ? (
            <>
              <Text>
                <strong>До: </strong>
                {client?.businessName}
              </Text>
              <Text>{client?.businessAddress}</Text>
              <Text>{client?.businessEmail} </Text>
            </>
          ) : null}
        </address>
      </div>
    </div>
  ) : null;
};
SpotlightExpenseDetail.displayName = 'SpotlightExpenseDetail';
