import { v4 } from 'uuid';
import { Toast } from './toaster.types';

const defaultOptions = {
  duration: 5432,
  isClosable: true,
  status: 'info',
  position: 'top-right',
};
export const createInfoToast = (toast?: Toast): Toast => ({
  id: v4(),
  ...defaultOptions,
  ...toast,
  status: 'info',
});

export const createSuccessToast = (toast?: Toast): Toast => ({
  id: v4(),
  ...defaultOptions,
  ...toast,
  status: 'success',
});

export const createErrorToast = (toast?: Toast): Toast => ({
  id: v4(),
  ...defaultOptions,
  ...toast,
  status: 'error',
});

export const createWarningToast = (toast?: Toast): Toast => ({
  id: v4(),
  ...defaultOptions,
  ...toast,
  status: 'warning',
});
