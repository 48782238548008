import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { ThunkAction } from 'redux-thunk';
import { authenticationReducer } from '../Authentication/store/authentication.reducers';
import { sidebarReducer } from '../Sidebar/store/sidebar.reducer';
import { themeReducer } from '../Theme/store/theme.reducer';
import { api } from './http/api';

const ui = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
});

const rootReducer = combineReducers({
  ui,
  authenticated: authenticationReducer,
  [api.reducerPath]: api.reducer,
});

const initStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export const store = initStore();
// see https://redux-toolkit.js.org/rtk-query/api/setupListeners - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppState = ReturnType<typeof rootReducer>;
export type AppStore = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, undefined, Action>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<AppState>();
export const useAppStore = useStore.withTypes<AppStore>();
