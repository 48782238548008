import { Box, ButtonGroup, Flex, Grid, GridItem, Heading, Text } from '@chakra-ui/react';
import { clsx } from 'clsx';
import * as React from 'react';
import { useIsLoadingNavigation } from '../../hooks/useIsLoading';
import styles from './Grid.module.scss';

interface CardProps {
  title?: string;
  children: React.ReactNode;
}

export const GridCard: React.FC<CardProps> = ({ title, children }) => {
  return (
    <Box data-testid={`grid-card-${title}`} py={2}>
      {title ? (
        <Box py={2}>
          <Text fontSize="xl" fontWeight="bold">
            {title}
          </Text>
        </Box>
      ) : null}
      <Box bg="white" borderRadius={6} p={6} mt={2} shadow="sm">
        {children}
      </Box>
    </Box>
  );
};

interface PageProps {
  title: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}
export const GridPage: React.FC<PageProps> = ({ title, actions, children }) => {
  const [isLoading] = useIsLoadingNavigation();

  return (
    <Flex className={clsx(styles.container, { [styles.isLoading]: isLoading })}>
      <Grid
        data-testid={`grid-page-${title}`}
        templateRows="auto 1fr"
        maxWidth="1152px"
        alignSelf="center"
        width="100%"
        maxW="1152px"
        height="100%"
        py={4}
      >
        <GridItem>
          <Flex justify="space-between" align="center">
            <Heading size="lg">{title}</Heading>
            <ButtonGroup>{actions}</ButtonGroup>
          </Flex>
        </GridItem>
        <GridItem>
          <Grid templateRows="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
            {children}
          </Grid>
        </GridItem>
      </Grid>
    </Flex>
  );
};
