import React from 'react';
import { HStack, Skeleton } from '@chakra-ui/react';

export const DashboardChartLoader = React.memo(() => {
  return (
    <HStack p={4} spacing={4} alignItems="flex-end" height="auto" maxHeight="360px" width="100%" maxW="860px">
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="200px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="300px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="140px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="360px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="200px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="80px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="280px" />
      <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="20%" height="340px" />
    </HStack>
  );
});

export default DashboardChartLoader;
