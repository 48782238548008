import clsx from 'clsx';
import * as React from 'react';
import { useIsLoadingNavigation } from '../../hooks/useIsLoading';
import styles from './SplitView.module.scss';

export const DetailView: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isLoading] = useIsLoadingNavigation();
  return <section className={clsx(styles.detail, { [styles.isLoading]: isLoading })}>{children}</section>;
};

DetailView.displayName = 'DetailView';
