import { isMobile } from 'react-device-detect';
import * as React from 'react';

export const useMobileViewHeight = () => {
  React.useLayoutEffect(() => {
    const resize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    if (isMobile) {
      resize();
      window.addEventListener('resize', resize);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('resize', resize);
      }
    };
  }, []);
};
