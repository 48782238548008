import { createEntityAdapter } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { http } from '../../Core/http/http';
import { Invoice } from '../../Invoices/api/invoices.types';
import { Estimate, EstimateSettings } from './estimates.types';

const { EstimatesUrl, PrintEstimateUrl } = ApiEndpoints;

export interface EstimateFilters {
  client?: string; // client id
  q?: string;
}

export const saveToPDF = async (estimate: Estimate, config?: AxiosRequestConfig) => {
  const { data } = await http.post<Estimate, { data: BlobPart }>(`${PrintEstimateUrl}/${estimate?.id}`, estimate, {
    ...config,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  });

  return { data };
};

const estimatesAdapter = createEntityAdapter<Estimate>();
const initialState = estimatesAdapter.getInitialState();

export const estimateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllEstimates: build.query<Record<string, Estimate>, void>({
      query: () => ({ url: EstimatesUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Estimates, { type: TagType.Estimates, id: `${TagType.Estimates}-LIST` }]
          : [{ type: TagType.Estimates, id: `${TagType.Estimates}-LIST` }];
      },
      transformResponse: (response: Estimate[]) => {
        const { entities } = estimatesAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getEstimate: build.query<Estimate, string>({
      query: (estimateId: string) => ({ method: 'GET', url: `/${EstimatesUrl}/${estimateId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Estimates, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createEstimate: build.mutation<Estimate, Estimate>({
      query: (estimate: Estimate) => ({ method: 'POST', url: EstimatesUrl, data: estimate }),
      invalidatesTags: () => [{ type: TagType.Estimates }, { type: TagType.DashboardCounters }],
      extraOptions: { maxRetries: 0 },
    }),
    updateEstimate: build.mutation<Estimate, Estimate>({
      query: (estimate: Estimate) => ({ method: 'PUT', url: `/${EstimatesUrl}/${estimate.id}`, data: estimate }),
      invalidatesTags: (_result, _error, estimate) => [
        { type: TagType.Estimates, id: estimate.id },
        { type: TagType.Estimates },
      ],
    }),
    deleteEstimate: build.mutation<Estimate, string>({
      query: (estimateId: string) => ({ method: 'DELETE', url: `/${EstimatesUrl}/${estimateId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Estimates, id: `${TagType.Estimates}-LIST` },
      ],
    }),
    replicateEstimate: build.mutation<Estimate, Estimate>({
      query: (estimate) => ({ method: 'POST', url: `/${EstimatesUrl}/replicate`, data: estimate }),
      invalidatesTags: (_result, _error, estimate) => [
        { type: TagType.Estimates, id: estimate.id },
        { type: TagType.Estimates, id: `${TagType.Estimates}-LIST` },
        { type: TagType.DashboardCounters },
      ],
    }),
    convertEstimateToInvoice: build.mutation<Invoice, Estimate>({
      query: (estimate) => ({ method: 'POST', url: `/${EstimatesUrl}/convert`, data: estimate }),
      invalidatesTags: () => [
        { type: TagType.Invoices },
        { type: TagType.Invoices, id: `${TagType.Invoices}-LIST` },
        { type: TagType.DashboardCounters },
      ],
    }),
    updateEstimateSettings: build.mutation<Estimate, { estimate: Estimate; settings: EstimateSettings }>({
      query: ({ estimate, settings }) => ({
        method: 'POST',
        url: `/${EstimatesUrl}/${estimate.id}/settings`,
        data: settings,
      }),
      invalidatesTags: (_result, _error, { estimate }) => [{ type: TagType.Estimates, id: estimate.id }],
    }),
  }),
});

export const estimatesLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(estimateApi.endpoints.getAllEstimates.initiate());
  request.signal.onabort = () => promise.abort();
  // await promise;
  promise.unsubscribe();
  return promise;
};

export const estimateLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(estimateApi.endpoints.getEstimate.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const initiateEstimateRequest = (id: string) => {
  const promise = store.dispatch(estimateApi.endpoints.getEstimate.initiate(id));
  promise.unsubscribe();
  return promise;
};

export const initiateAllEstimatesRequest = () => {
  const promise = store.dispatch(estimateApi.endpoints.getAllEstimates.initiate());
  promise.unsubscribe();
  return promise;
};

export const {
  useGetAllEstimatesQuery,
  useGetEstimateQuery,
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
  useDeleteEstimateMutation,
  useReplicateEstimateMutation,
  useUpdateEstimateSettingsMutation,
  useConvertEstimateToInvoiceMutation,
} = estimateApi;
