import { AuthUser } from '../../Authentication/store/authentication.types';

const CURRENT_USER = 'brojki:currentUser';

type OptionalUser = AuthUser | null;
const getUser = (): OptionalUser => {
  try {
    const currentUser = localStorage.getItem(CURRENT_USER);
    return currentUser != null ? JSON.parse(currentUser) : null;
  } catch {
    return null;
  }
};

export const getCurrentUserFromLocalStorage = (): [OptionalUser] => {
  try {
    return [getUser()];
  } catch {
    return [null];
  }
};

export const useGetCurrentUser = () => {
  const [user] = getCurrentUserFromLocalStorage();

  return [user] as const;
};
