export const lazyRetry = <T,>(componentImport: () => Promise<T>, name: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const refreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    // try to import the component
    return componentImport()
      .then((component: T) => {
        // reset the refresh
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      .catch((error: Error) => {
        if (!refreshed) {
          // we are now going to refresh
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          // refresh the page
          return window.location.reload();
        }
        reject(error);
      });
  });
};
