import { createEntityAdapter } from '@reduxjs/toolkit';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { TODO } from '../../Core/core.types';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { http } from '../../Core/http/http';
import { Expense, FileDocument } from './expenses.types';

const { ExpensesUrl, PrintExpenseUrl } = ApiEndpoints;

interface ExpenseResponse {
  data: Expense;
}

export interface ExpenseFilters {
  client?: string; // client id
  q?: string;
}

export const uploadExpenseDocument = async (
  { document, expense }: { document: FormData; expense?: Expense },
  config?: AxiosRequestConfig,
): Promise<ExpenseResponse> => {
  const { data } = await http
    .post<FormData, TODO>(`${ExpensesUrl}/${expense?.id}/documents`, document, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);
  return { data };
};

export const deleteExpenseDocument = async (
  { document, expense }: { document: FileDocument; expense?: Expense },
  config?: AxiosRequestConfig,
): Promise<ExpenseResponse> => {
  const { data } = await http
    .delete<FormData, TODO>(`${ExpensesUrl}/${expense?.id}/documents/${document.id}`, config)
    .then(({ data }) => data);
  return { data };
};

export const saveToPDF = async (expense: Expense, config?: AxiosRequestConfig): Promise<TODO> => {
  const { data } = await http.post<Expense, AxiosResponse<ExpenseResponse>>(
    `${PrintExpenseUrl}/${expense?.id}`,
    expense,
    {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  );

  return { data };
};

const expensesAdapter = createEntityAdapter<Expense>();
const initialState = expensesAdapter.getInitialState();

export const expenseApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllExpenses: build.query<Record<string, Expense>, void>({
      query: () => ({ url: ExpensesUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Expenses, { type: TagType.Expenses, id: `${TagType.Expenses}-LIST` }]
          : [{ type: TagType.Expenses, id: `${TagType.Expenses}-LIST` }];
      },
      transformResponse: (response: Expense[]) => {
        const { entities } = expensesAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getExpense: build.query<Expense, string>({
      query: (expenseId: string) => ({ method: 'GET', url: `/${ExpensesUrl}/${expenseId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Expenses, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createExpense: build.mutation<Expense, Expense>({
      query: (expense: Expense) => ({ method: 'POST', url: ExpensesUrl, data: expense }),
      invalidatesTags: () => [
        { type: TagType.Expenses },
        { type: TagType.DashboardCounters },
        { type: TagType.DashboardExpensesChart },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    updateExpense: build.mutation<Expense, Expense>({
      query: (expense: Expense) => ({ method: 'PUT', url: `/${ExpensesUrl}/${expense.id}`, data: expense }),
      invalidatesTags: (_result, _error, expense) => [
        { type: TagType.Expenses, id: expense.id },
        { type: TagType.Expenses },
        { type: TagType.Expenses, id: `${TagType.Expenses}-LIST` },
        { type: TagType.DashboardExpensesChart },
      ],
    }),
    deleteExpense: build.mutation<Expense, string>({
      query: (expenseId: string) => ({ method: 'DELETE', url: `/${ExpensesUrl}/${expenseId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Expenses, id: `${TagType.Expenses}-LIST` },
        { type: TagType.DashboardExpensesChart },
      ],
    }),
  }),
});

export const expensesLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(expenseApi.endpoints.getAllExpenses.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  return promise;
};

export const expenseLoader = async ({ params, request }: LoaderFunctionArgs) => {
  if (!params.id) {
    return Promise.resolve(null);
  }
  const promise = store.dispatch(expenseApi.endpoints.getExpense.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  return promise;
};

export const initiateExpenseRequest = (id: string) => {
  const promise = store.dispatch(expenseApi.endpoints.getExpense.initiate(id));
  promise.unsubscribe();
  return promise;
};

export const initiateAllExpensesRequest = () => {
  const promise = store.dispatch(expenseApi.endpoints.getAllExpenses.initiate());
  promise.unsubscribe();
  return promise;
};

export const {
  useGetAllExpensesQuery,
  useGetExpenseQuery,
  useCreateExpenseMutation,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
} = expenseApi;
