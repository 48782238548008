import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup, Portal } from '@chakra-ui/react';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useGetAllInvoicesQuery } from '../../Invoices/api/invoices.api';
import { TreasuryTheme } from '../store/treasuries.types';

interface Props {
  switchTheme?: (theme: TreasuryTheme) => TreasuryTheme | unknown;
  selected?: TreasuryTheme;
  isLoading?: boolean;
}

export const ThemeSwitcherMenu: React.FC<React.PropsWithChildren<Props>> = ({ switchTheme, isLoading, selected }) => {
  const { isLoading: isFetching } = useGetAllInvoicesQuery();
  const shouldDisableActions = isLoading || isFetching;

  return (
    <Menu>
      <MenuButton
        size="sm"
        as={Button}
        rightIcon={<FontAwesomeIcon icon={faAngleDown} />}
        disabled={shouldDisableActions}
        isLoading={shouldDisableActions}
      >
        Шаблон
      </MenuButton>
      <Portal>
        <MenuList minWidth="220px" zIndex={999999}>
          <MenuOptionGroup defaultValue={selected} type="radio" value={selected}>
            <MenuItemOption isDisabled={isLoading} value="Simple" onClick={() => switchTheme?.(TreasuryTheme.Simple)}>
              Simple
            </MenuItemOption>
            <MenuItemOption isDisabled={isLoading} value="Modern" onClick={() => switchTheme?.(TreasuryTheme.Modern)}>
              Modern
            </MenuItemOption>
            <MenuItemOption isDisabled={isLoading} value="Classic" onClick={() => switchTheme?.(TreasuryTheme.Classic)}>
              Classic
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
ThemeSwitcherMenu.displayName = 'ThemeSwitcherMenu';
