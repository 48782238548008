import {
  Badge,
  Box,
  Link as ChakraLink,
  Flex,
  IconButton,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { faBell, faBellOn } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { TODO } from '../../Core/core.types';
import { getSortedEntityItems } from '../../Core/utils/getSortedEntityItems';
import { useGetAllNotificationsQuery, useMarkAsReadMutation } from '../api/notifications.api';

export const NotificationsPopover: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data } = useGetAllNotificationsQuery();
  const notifications = useMemo(() => getSortedEntityItems<TODO>(data), [data]);
  const [markAsRead] = useMarkAsReadMutation();
  const unreadCount = notifications.length;

  const onReadMoreClick = (id: string) => {
    markAsRead(id);
    onClose();
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom" closeOnBlur={true}>
      <PopoverTrigger>
        <Box position="relative" display="inline-block">
          <IconButton
            size="lg"
            fontSize="24px"
            aria-label="Notifications"
            icon={<FontAwesomeIcon icon={unreadCount > 0 ? faBellOn : faBell} />}
            onClick={onOpen}
            variant="ghost"
          />
          {unreadCount > 0 ? (
            <Badge
              fontSize="0.85rem"
              colorScheme="red"
              rounded="full"
              borderRadius="full"
              position="absolute"
              top="1"
              right="1"
              zIndex="1"
              textAlign="center"
              width="1.2rem"
            >
              {unreadCount}
            </Badge>
          ) : null}
        </Box>
      </PopoverTrigger>
      <Portal>
        <Box zIndex={9999} position="relative">
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Нотификации</PopoverHeader>
            <PopoverBody>
              {notifications.length > 0 ? (
                <List spacing={3}>
                  {notifications?.map((notification) => (
                    <ListItem key={notification.id}>
                      <Flex flexDir="column">
                        <Text fontWeight="bold">{notification.data.title}</Text>
                        <Text
                          fontSize="sm"
                          noOfLines={3}
                          dangerouslySetInnerHTML={{ __html: notification.data.content }}
                        />

                        <ChakraLink
                          as={Link}
                          to={`/notifications/${notification.id}`}
                          mt="2"
                          alignSelf="flex-end"
                          color="teal.500"
                          ml="auto"
                          onClick={() => onReadMoreClick(notification.id)}
                        >
                          Читај повеќе
                        </ChakraLink>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Text textAlign="center" fontWeight="bold" fontSize="1rem">
                  Нема нови активности
                </Text>
              )}
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};
