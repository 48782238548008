import isEmpty from 'lodash-es/isEmpty';
import round from 'lodash-es/round';
import toFinite from 'lodash-es/toFinite';
import { DocumentLineItem } from '../../Invoices/api/invoices.types';
import { Treasury } from './treasuries.types';

export enum Currency {
  EUR = 'EUR',
  MKD = 'MKD',
  USD = 'USD',
  ALL = 'ALL',
  SEK = 'SEK',
}

/**
 * ### Round amount to the specified precision
 * * Uses - lodash/round
 */
export const roundAmount = (amount = 0, precision = 2): number => round(amount, precision);

/**
 * * Calculate TAX amount given Net Price and Tax Percentage
 */
export const calculateTaxAmount = (netPrice = 0, taxPercentage = 0): number => {
  const salesTaxAmount: number = netPrice * (taxPercentage / 100);

  return roundAmount(salesTaxAmount);
};

/**
 * * Calculate price without VAT
 */
export const calculateSubTotal = (quantity = 0, unitPrice = 0): number => {
  const subTotal = quantity * unitPrice;

  return roundAmount(subTotal);
};

/**
 * * Calculate price with VAT included
 */
export const calculateTotalAmount = (price: number, salesTaxAmount = 0): number => {
  const totalPriceVATIncluded = price + salesTaxAmount;

  return roundAmount(totalPriceVATIncluded);
};

export const calculateDiscountedPrice = (originalPrice: number, discount = 0): number => {
  const discountedPrice = originalPrice - (originalPrice * discount) / 100;

  return roundAmount(discountedPrice);
};

export const getLineSubTotal = (line: DocumentLineItem): number => {
  const quantity = toFinite(line.quantity);
  const price = toFinite(line.unitPrice);

  return calculateSubTotal(quantity, price);
};

export const getLineTaxAmount = (line: DocumentLineItem): number => {
  const subTotal = getLineSubTotal(line);

  return calculateTaxAmount(subTotal, line.taxRate);
};

export const getLineTotalAmount = (line: DocumentLineItem): number => {
  const subTotal = getLineSubTotal(line);
  const discount = line.discount ?? 0;
  const discountedPrice = calculateDiscountedPrice(subTotal, discount);
  const taxAmount = getLineTaxAmount(line);

  return calculateTotalAmount(discountedPrice, taxAmount);
};

export const getTreasuryTotalTaxAmount = (treasury: Treasury): number => {
  if (isEmpty(treasury.lineItems)) {
    return 0;
  }

  const total = treasury.lineItems.reduce((total: number, next: DocumentLineItem) => {
    return total + getLineTaxAmount(next);
  }, 0);

  return roundAmount(total);
};

export const getTreasurySubTotal = (treasury: Treasury): number => {
  if (isEmpty(treasury.lineItems)) {
    return 0;
  }

  const total = treasury.lineItems.reduce((total: number, next: DocumentLineItem) => {
    return total + getLineSubTotal(next);
  }, 0);

  return roundAmount(total);
};

/**
 * Calculates the subtotal with the line item discount applied
 *
 * @param treasury
 * @returns
 */
export const getDiscountedTreasurySubTotal = (treasury: Treasury): number => {
  if (isEmpty(treasury.lineItems)) {
    return 0;
  }

  const total = treasury.lineItems.reduce((total: number, next: DocumentLineItem) => {
    const subTotal = calculateDiscountedPrice(getLineSubTotal(next), next.discount);
    return total + subTotal;
  }, 0);

  return roundAmount(total);
};

export const getTreasuryTotalAmount = (treasury: Treasury): number => {
  if (isEmpty(treasury.lineItems)) {
    return 0;
  }

  const total = treasury.lineItems.reduce((total: number, next: DocumentLineItem) => {
    return total + getLineTotalAmount(next);
  }, 0);

  return roundAmount(total);
};

export const formatMoney = (
  amount = 0,
  currency: Currency = Currency.MKD,
  style: 'currency' | 'unit' | 'percent' | 'decimal' = 'currency',
) => {
  const roundedAmount = round(amount);

  const money = new Intl.NumberFormat('mk-MK', {
    style,
    ...(style === 'currency' ? { currency } : {}),
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(roundedAmount);

  if (style === 'currency' && currency === Currency.MKD) {
    // NOTE: Firefox and Safari use the correct currency formatting but not Chrome
    return money.includes('ден') ? money : `${money.replace('MKD', '')} ден.`;
  }

  return money;
};

/**
 * Formats a number using the browser's locale.
 */
export const formatNumber = (
  amount = 0,
  style: 'currency' | 'unit' | 'percent' | 'decimal' = 'decimal',
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string => {
  const rounded = round(amount);
  return new Intl.NumberFormat('mk-MK', {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(rounded);
};
