import { parseISO } from 'date-fns';
import { Creatable } from './standard.model';

export const getSortedEntityItems = <T extends Creatable>(items?: Record<string, T>) => {
  if (!items) {
    return [];
  }

  return Object.values(items).sort((a, b) => {
    return parseISO(b?.createdAt as string).getTime() - parseISO(a?.createdAt as string).getTime();
  });
};
