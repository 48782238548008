import { createEntityAdapter } from '@reduxjs/toolkit';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Project } from './projects.types';

const { ProjectsUrl } = ApiEndpoints;

const projectsAdapter = createEntityAdapter<Project>();
const initialState = projectsAdapter.getInitialState();

export const projectApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllProjects: build.query<Record<string, Project>, void>({
      query: () => ({ url: ProjectsUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Projects, { type: TagType.Projects, id: `${TagType.Projects}-LIST` }]
          : [{ type: TagType.Projects, id: `${TagType.Projects}-LIST` }];
      },
      transformResponse: (response: Project[]) => {
        const { entities } = projectsAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getProject: build.query<Project, string>({
      query: (projectId: string) => ({ method: 'GET', url: `/${ProjectsUrl}/${projectId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Projects, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createProject: build.mutation<Project, Project>({
      query: (project: Project) => ({ method: 'POST', url: ProjectsUrl, data: project }),
      invalidatesTags: () => [{ type: TagType.Projects }, { type: TagType.DashboardCounters }],
      extraOptions: { maxRetries: 0 },
    }),
    updateProject: build.mutation<Project, Project>({
      query: (project: Project) => ({ method: 'PUT', url: `/${ProjectsUrl}/${project.id}`, data: project }),
      invalidatesTags: (_result, _error, project) => [
        { type: TagType.Projects, id: project.id },
        { type: TagType.Projects },
      ],
    }),
    deleteProject: build.mutation<Project, string>({
      query: (projectId: string) => ({ method: 'DELETE', url: `/${ProjectsUrl}/${projectId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Projects, id: `${TagType.Projects}-LIST` },
      ],
    }),
  }),
});

export const projectsLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(projectApi.endpoints.getAllProjects.initiate());
  request.signal.onabort = () => promise.abort();
  // await promise;
  promise.unsubscribe();
  return promise;
};

export const projectLoader = async ({ params, request }: LoaderFunctionArgs) => {
  if (!params.id) {
    return Promise.resolve(null);
  }
  const promise = store.dispatch(projectApi.endpoints.getProject.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  // await promise;
  promise.unsubscribe();
  return promise;
};

export const initiateProjectRequest = (id: string) => {
  const request = store.dispatch(projectApi.endpoints.getProject.initiate(id));
  request.unsubscribe();
  return request;
};

export const initiateAllProjectsRequest = () => {
  const request = store.dispatch(projectApi.endpoints.getAllProjects.initiate());
  request.unsubscribe();
  return request;
};

export const {
  useGetAllProjectsQuery,
  useGetProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
} = projectApi;
