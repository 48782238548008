import { createEntityAdapter } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { http } from '../../Core/http/http';
import { CashPayment, FileDocument } from './payments.types';

const { CashPaymentsUrl, PrintPaymentUrl } = ApiEndpoints;

interface CashPaymentResponse {
  data: CashPayment;
}

export interface CashPaymentFilters {
  client?: string; // client id
  q?: string;
}

export const uploadPaymentDocument = async (
  { document, cashPayment }: { document: FormData; cashPayment?: CashPayment },
  config?: AxiosRequestConfig,
): Promise<CashPaymentResponse> => {
  const { data } = await http
    .post<FormData, { data: { data: CashPayment } }>(`${CashPaymentsUrl}/${cashPayment?.id}/documents`, document, {
      ...config,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(({ data }) => data);

  return { data };
};

export const deletePaymentDocument = async (
  { document, cashPayment }: { document: FileDocument; cashPayment?: CashPayment },
  config?: AxiosRequestConfig,
): Promise<CashPaymentResponse> => {
  const { data } = await http
    .delete<
      FormData,
      { data: { data: CashPayment } }
    >(`${CashPaymentsUrl}/${cashPayment?.id}/documents/${document.id}`, config)
    .then(({ data }) => data);
  return { data };
};

export const saveToPDF = async (cashPayment: CashPayment, config?: AxiosRequestConfig) => {
  const { data } = await http.post<CashPayment, { data: BlobPart }>(
    `${PrintPaymentUrl}/${cashPayment?.id}`,
    cashPayment,
    {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      responseType: 'blob',
    },
  );

  return { data };
};

const paymentsAdapter = createEntityAdapter<CashPayment>();
const initialState = paymentsAdapter.getInitialState();

export const cashPaymentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCashPayments: build.query<Record<string, CashPayment>, void>({
      query: () => ({ url: CashPaymentsUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.CashPayments, { type: TagType.CashPayments, id: `${TagType.CashPayments}-LIST` }]
          : [{ type: TagType.CashPayments, id: `${TagType.CashPayments}-LIST` }];
      },
      transformResponse: (response: CashPayment[]) => {
        const { entities } = paymentsAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getCashPayment: build.query<CashPayment, string>({
      query: (paymentId: string) => ({ method: 'GET', url: `/${CashPaymentsUrl}/${paymentId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.CashPayments, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createCashPayment: build.mutation<CashPayment, CashPayment>({
      query: (payment: CashPayment) => ({ method: 'POST', url: CashPaymentsUrl, data: payment }),
      invalidatesTags: () => [
        { type: TagType.CashPayments },
        { type: TagType.DashboardCounters },
        { type: TagType.DashboardPaymentsChart },
      ],
      extraOptions: { maxRetries: 0 },
    }),
    updateCashPayment: build.mutation<CashPayment, CashPayment>({
      query: (payment: CashPayment) => ({ method: 'PUT', url: `/${CashPaymentsUrl}/${payment.id}`, data: payment }),
      invalidatesTags: (_result, _error, payment) => [
        { type: TagType.CashPayments, id: payment.id },
        { type: TagType.CashPayments },
        { type: TagType.CashPayments, id: `${TagType.CashPayments}-LIST` },
        { type: TagType.DashboardPaymentsChart },
      ],
    }),
    deleteCashPayment: build.mutation<CashPayment, string>({
      query: (paymentId: string) => ({ method: 'DELETE', url: `/${CashPaymentsUrl}/${paymentId}` }),
      invalidatesTags: () => [
        { type: TagType.Trash },
        { type: TagType.DashboardCounters },
        { type: TagType.CashPayments, id: `${TagType.CashPayments}-LIST` },
        { type: TagType.DashboardPaymentsChart },
      ],
    }),
  }),
});

export const cashPaymentsLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(cashPaymentApi.endpoints.getAllCashPayments.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const cashPaymentLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(cashPaymentApi.endpoints.getCashPayment.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const initiatePaymentRequest = (paymentId: string) => {
  const promise = store.dispatch(cashPaymentApi.endpoints.getCashPayment.initiate(paymentId));
  promise.unsubscribe();
  return promise;
};

export const initiateAllPaymentsRequest = () => {
  const promise = store.dispatch(cashPaymentApi.endpoints.getAllCashPayments.initiate());
  promise.unsubscribe();
  return promise;
};

export const {
  useGetAllCashPaymentsQuery,
  useGetCashPaymentQuery,
  useCreateCashPaymentMutation,
  useUpdateCashPaymentMutation,
  useDeleteCashPaymentMutation,
} = cashPaymentApi;
