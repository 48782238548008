import { createEntityAdapter } from '@reduxjs/toolkit';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Product } from './products.types';

const { ProductsUrl } = ApiEndpoints;

const productsAdapter = createEntityAdapter<Product>();
const initialState = productsAdapter.getInitialState();

export const productApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllProducts: build.query<Record<string, Product>, void>({
      query: () => ({ url: ProductsUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Products, { type: TagType.Products, id: `${TagType.Products}-LIST` }]
          : [{ type: TagType.Products, id: `${TagType.Products}-LIST` }];
      },
      transformResponse: (response: Product[]) => {
        const { entities } = productsAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getProduct: build.query<Product, string>({
      query: (productId: string) => ({ method: 'GET', url: `/${ProductsUrl}/${productId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Products, id }],
      extraOptions: { maxRetries: 2 },
    }),
    createProduct: build.mutation<Product, Product>({
      query: (product: Product) => ({ method: 'POST', url: ProductsUrl, data: product }),
      invalidatesTags: () => [{ type: TagType.Products }, { type: TagType.DashboardCounters }],
      extraOptions: { maxRetries: 0 },
    }),
    updateProduct: build.mutation<Product, Product>({
      query: (product: Product) => ({ method: 'PUT', url: `/${ProductsUrl}/${product.id}`, data: product }),
      invalidatesTags: (_result, _error, product) => [
        { type: TagType.Products, id: product.id },
        { type: TagType.Products },
      ],
    }),
    deleteProduct: build.mutation<Product, string>({
      query: (productId: string) => ({ method: 'DELETE', url: `/${ProductsUrl}/${productId}` }),
      invalidatesTags: () => [
        { type: TagType.DashboardCounters },
        { type: TagType.Trash },
        { type: TagType.Products, id: `${TagType.Products}-LIST` },
      ],
    }),
  }),
});

export const productsLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(productApi.endpoints.getAllProducts.initiate());
  request.signal.onabort = () => promise.abort();
  // await promise;
  promise.unsubscribe();
  return promise;
};

export const productLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(productApi.endpoints.getProduct.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const initiateProductRequest = (id: string) => {
  const request = store.dispatch(productApi.endpoints.getProduct.initiate(id));
  request.unsubscribe();
  return request;
};

export const initiateAllProductsRequest = () => {
  const request = store.dispatch(productApi.endpoints.getAllProducts.initiate());
  request.unsubscribe();
  return request;
};

export const {
  useGetAllProductsQuery,
  useGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productApi;
