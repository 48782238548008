import { isMobile } from 'react-device-detect';
import { MediaQuery } from '../utils/mediaQuery';
import { useMediaMatch } from './useMediaMatch';

/**
 * Check whether viewing from mobile
 *
 * Relies first on UA otherwise fallbacks to screensize (max-width: 599px)
 */
export const useIsOnMobile = (): boolean => {
  const isSmall: boolean = useMediaMatch(MediaQuery.small);

  return isMobile || isSmall;
};
