import { createEntityAdapter } from '@reduxjs/toolkit';
import { LoaderFunctionArgs } from 'react-router';
import { store } from '../../Core/AppStore';
import { api, TagType } from '../../Core/http/api';
import { ApiEndpoints } from '../../Core/http/api-endpoints';
import { Notification, NotificationData } from './notifications.types';

const { NotificationsUrl } = ApiEndpoints;

const notificationsAdapter = createEntityAdapter<Notification>();
const initialState = notificationsAdapter.getInitialState();

export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllNotifications: build.query<Record<string, Notification>, void>({
      query: () => ({ url: NotificationsUrl, method: 'GET' }),
      providesTags: (result) => {
        return result
          ? [TagType.Notifications, { type: TagType.Notifications, id: `${TagType.Notifications}-LIST` }]
          : [{ type: TagType.Notifications, id: `${TagType.Notifications}-LIST` }];
      },
      transformResponse: (response: Notification[]) => {
        const { entities } = notificationsAdapter.setAll(initialState, response);
        return entities;
      },
    }),
    getNotification: build.query<NotificationData, string>({
      query: (notificationId: string) => ({ method: 'GET', url: `/${NotificationsUrl}/${notificationId}` }),
      providesTags: (_result, _error, id) => [{ type: TagType.Notifications, id }],
      extraOptions: { maxRetries: 2 },
    }),
    markAsRead: build.mutation<Notification, string>({
      query: (notificationId: string) => ({
        method: 'POST',
        url: `/${NotificationsUrl}/${notificationId}/mark-as-read`,
      }),
      invalidatesTags: (_, _error, id) => [
        { type: TagType.Notifications, id },
        {
          type: TagType.Notifications,
          id: `${TagType.Notifications}-LIST`,
        },
      ],
    }),
  }),
});

export const notificationsLoader = async ({ request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(notificationApi.endpoints.getAllNotifications.initiate());
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const notificationLoader = async ({ params, request }: LoaderFunctionArgs) => {
  const promise = store.dispatch(notificationApi.endpoints.getNotification.initiate(params.id!));
  request.signal.onabort = () => promise.abort();
  await promise;
  promise.unsubscribe();
  return promise;
};

export const { useGetAllNotificationsQuery, useGetNotificationQuery, useMarkAsReadMutation } = notificationApi;
