import { Box, Flex, Skeleton } from '@chakra-ui/react';
import * as React from 'react';
import { HideOnMobile } from '../Helpers/HideOnMobile';
import { MasterViewToolbar, ToolbarAction } from '../SplitView/MasterViewToolbar';

const ItemLoader = React.memo(() => {
  return (
    <Box width="100%" height="auto" p={4} borderBottom="1px solid #f3f3f3" mb={4}>
      <Flex direction="row" justifyContent="space-between" mb={4}>
        <Skeleton width="40%" startColor="#f3f3f3" endColor="#ecebeb" height="10px" mt={2} borderRadius="8px" />
        <Skeleton width="40%" startColor="#f3f3f3" endColor="#ecebeb" height="10px" mt={2} borderRadius="8px" />
      </Flex>
      <Flex direction="column" mb={4}>
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="10px" mt={2} borderRadius="8px" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="10px" mt={2} borderRadius="8px" width="80%" />
        <Skeleton startColor="#f3f3f3" endColor="#ecebeb" height="10px" mt={2} borderRadius="8px" width="60%" />
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Skeleton width="42px" height="42px" startColor="#f3f3f3" endColor="#ecebeb" mt={2} borderRadius="8px" />
        <Skeleton width="42px" height="42px" startColor="#f3f3f3" endColor="#ecebeb" mt={2} borderRadius="8px" />
        <Skeleton width="42px" height="42px" startColor="#f3f3f3" endColor="#ecebeb" mt={2} borderRadius="8px" />
        <Skeleton width="42px" height="42px" startColor="#f3f3f3" endColor="#ecebeb" mt={2} borderRadius="8px" />
      </Flex>
    </Box>
  );
});

export const MasterViewLoader = React.memo(() => {
  return (
    <Box bg="white" width="100%" overflow="hidden">
      <HideOnMobile>
        <Box style={{ pointerEvents: 'none' }}>
          <MasterViewToolbar isDisabled entity="None">
            <ToolbarAction isCreator isDisabled label="Нова Фактура" />
          </MasterViewToolbar>
        </Box>
      </HideOnMobile>
      <Box width="100%">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {Array(10)
            .fill(undefined)
            .map((_, i) => (
              <ItemLoader key={i} />
            ))}
        </Box>
      </Box>
    </Box>
  );
});
