import { api } from '../../Core/http/api';
import { SpotlightEntities } from './spotlight.types';
import { ApiEndpoints } from '../../Core/http/api-endpoints';

const { SpotlightSearchUrl } = ApiEndpoints;

export interface SpotlightResponse {
  data: SpotlightEntities;
}

export const spotlightApi = api.injectEndpoints({
  endpoints: (build) => ({
    search: build.query<SpotlightEntities, string>({
      query: (query: string) => ({ method: 'GET', url: `/${SpotlightSearchUrl}`, params: { q: query } }),
    }),
  }),
});

export const { useSearchQuery } = spotlightApi;
