import { Box, Divider, useDisclosure, useToast } from '@chakra-ui/react';

import {
  faCalculatorSimple,
  faCashRegister,
  faChartUser,
  faChevronLeft,
  faChevronRight,
  faCubes,
  faFileInvoiceDollar,
  faFolderOpen,
  faMoneyCheckDollarPen,
  faRightFromBracket,
  faSearch,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { initiateAllPaymentsRequest } from '../../CashPayments/api/payments.api';
import { initiateAllClientsRequest } from '../../Clients/api/clients.api';
import { useAppDispatch, useAppSelector } from '../../Core/AppStore';
import { authService } from '../../Core/auth/authService';
import { useIsOnMobile } from '../../Core/hooks/useIsOnMobile';
import { useSidebarCollapsed } from '../../Core/hooks/useSidebarCollapsed';
import { Feature, useShouldEnableFeature } from '../../Core/utils/shouldEnableFeature';
import { initiateAllEstimatesRequest } from '../../Estimates/api/estimates.api';
import { initiateAllExpensesRequest } from '../../Expenses/api/expenses.api';
import { initiateAllInvoicesRequest } from '../../Invoices/api/invoices.api';
import { initiateAllProductsRequest } from '../../Products/api/products.api';
import { initiateAllProjectsRequest } from '../../Projects/api/projects.api';
import { Spotlight } from '../../Spotlight/components/Spotlight';
import { toggleMobileSidebar, toggleSidebar } from '../store/sidebar.actions';
import { MenuItemButton } from './MenuItemButton';
import { MenuItemLink } from './MenuItemLink';
import { MobileSideBar } from './MobileSideBar';

import './SideBar.scss';

// function FeedbackButton({ isCollapsed = false }) {
//   const client = getClient<BrowserClient>();
//   const feedback = client?.getIntegration(Feedback)

//   return feedback ? (
//     <>
//       <MenuItemButton
//         icon={<FontAwesomeIcon icon={faMessageLines} />}
//         label="Остави Фидбек"
//         onClick={() => feedback.openDialog()}
//         isCollapsed={isCollapsed}
//       >
//         Остави Фидбек
//       </MenuItemButton>
//       <Divider mb={1} mt={1} borderColor="#e3e8ef" />
//     </>
//   ) : null;
// }

export const SideBar: React.FC = () => {
  const toast = useToast();
  const [shouldEnablePayments] = useShouldEnableFeature(Feature.ViewPaymentsModule);
  const dispatch = useAppDispatch();
  const isOnMobile = useIsOnMobile();
  const isCollapsed = useSidebarCollapsed(isOnMobile);
  const isMobileSideBarOpen: boolean = useAppSelector(({ ui }) => ui.sidebar.isOpen || false);
  const theme = useAppSelector(({ ui }) => ui.theme.name);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Used for the Spotlight Modal
  const { onOpen: onOpenMobileSideBar } = useDisclosure(); // Used for the MobileSidebar
  const sidebarClassNames = clsx('Sidebar', theme, { collapsed: isCollapsed || isOnMobile }, 'd-print-none');
  const menuClassNames = clsx('Sidebar-menu', { collapsed: isCollapsed || isOnMobile }, 'd-print-none');

  useHotkeys(
    'mod+k',
    () => {
      if (isOpen) {
        return;
      }
      onOpen();
    },
    [],
  );

  const toggleMenu = () => dispatch(toggleSidebar(!isCollapsed));

  return (
    <>
      {isOnMobile ? (
        <MobileSideBar isOpen={isMobileSideBarOpen} onOpen={onOpenMobileSideBar}>
          {/*<FeedbackButton isCollapsed={isCollapsed} />*/}
          {/*<Divider mb={1} mt={1} borderColor="#075985" />*/}
          <MenuItemButton
            label="Брзо Пребарување"
            icon={<FontAwesomeIcon icon={faSearch} />}
            isCollapsed={isCollapsed}
            onClick={() => {
              onOpen?.();
              dispatch(toggleMobileSidebar(false));
            }}
          >
            Брзо Пребарување
          </MenuItemButton>
        </MobileSideBar>
      ) : (
        <aside className={sidebarClassNames}>
          <nav className={menuClassNames}>
            <ul style={{ paddingLeft: 0 }}>
              <MenuItemLink to="/dashboard" icon={<FontAwesomeIcon icon={faChartUser} />} label="Инфо Панел" />
              <MenuItemLink
                to="/invoices"
                icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                label="Фактури"
                onMouseEnter={() => initiateAllInvoicesRequest()}
              />
              <MenuItemLink
                to="/estimates"
                icon={<FontAwesomeIcon icon={faCalculatorSimple} />}
                label="Пресметки"
                onMouseEnter={() => initiateAllEstimatesRequest()}
              />
              <MenuItemLink
                to="/expenses"
                icon={<FontAwesomeIcon icon={faMoneyCheckDollarPen} />}
                label="Расходи"
                onMouseEnter={() => initiateAllExpensesRequest()}
              />
              {shouldEnablePayments ? (
                <MenuItemLink
                  to="/cash-payments"
                  icon={<FontAwesomeIcon icon={faCashRegister} />}
                  label="Каса Прими"
                  onMouseEnter={() => initiateAllPaymentsRequest()}
                />
              ) : null}
              <MenuItemLink
                to="/clients"
                icon={<FontAwesomeIcon icon={faUsers} />}
                label="Клиенти"
                onMouseEnter={() => initiateAllClientsRequest()}
              />
              <MenuItemLink
                to="/projects"
                icon={<FontAwesomeIcon icon={faFolderOpen} />}
                label="Проекти"
                onMouseEnter={() => initiateAllProjectsRequest()}
              />
              <MenuItemLink
                to="/products"
                icon={<FontAwesomeIcon icon={faCubes} />}
                label="Производи и Услуги"
                onMouseEnter={() => initiateAllProductsRequest()}
              />

              <Divider mb={1} mt={1} borderColor="#e3e8ef" />
              <MenuItemButton
                label="Брзо Пребарување"
                icon={<FontAwesomeIcon icon={faSearch} />}
                isCollapsed={isCollapsed}
                onClick={() => onOpen?.()}
              />

              <Divider mb={1} mt={1} borderColor="#e3e8ef" />
              {/* <FeedbackButton isCollapsed={isCollapsed} /> */}
              <MenuItemButton
                label="Одјавете се"
                icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                isCollapsed={isCollapsed}
                onClick={() => {
                  toast({
                    title: 'Одјавување!',
                    description: 'Одјавување од апликацијата...',
                    status: 'info',
                    position: 'top-right',
                    duration: 2000,
                    isClosable: true,
                  });
                  setTimeout(() => authService.logout(), 456);
                }}
              >
                Одјавете се
              </MenuItemButton>
            </ul>
          </nav>
          {isOnMobile ? null : (
            <footer className="Sidebar-footer">
              <ul className={clsx('m-0 p-0', { collapsed: isCollapsed })}>
                <li className="Sidebar-menu__item icon-item" onClick={toggleMenu}>
                  <Box verticalAlign="middle" textAlign="center" fontSize="16px" boxSize="22px">
                    <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
                  </Box>
                </li>
              </ul>
            </footer>
          )}
        </aside>
      )}
      <Spotlight isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </>
  );
};
SideBar.displayName = 'SideBar';
