import * as Sentry from '@sentry/react';
import isEmpty from 'lodash-es/isEmpty';
import { AuthUser } from '../../Authentication/store/authentication.types';
import { ApiEndpoints } from '../http/api-endpoints';
import { http } from '../http/http';

export const CURRENT_USER_STORAGE_KEY = 'brojki:currentUser';

interface AuthSession {
  name?: string;
  company_name?: string;
  email: string;
  password: string;
  password_confirmation?: string;
  remember?: boolean;
}

class AuthService {
  public async login({ email, password, remember }: AuthSession): Promise<AuthUser> {
    await http.get(ApiEndpoints.CSRFCookie);
    const { data } = await http.post(ApiEndpoints.LoginUrl, { email, password, remember });
    // await http.get(ApiEndpoints.UserProfileUrl);
    return this.setCurrentUser(data as unknown as { data: AuthUser });
  }

  public async signup({ name, company_name, email, password, password_confirmation }: AuthSession): Promise<AuthUser> {
    await http.get(ApiEndpoints.CSRFCookie);
    const { data } = await http.post(ApiEndpoints.SignupUrl, {
      name,
      company_name,
      email,
      password,
      password_confirmation,
    });
    return this.setCurrentUser(data as unknown as { data: AuthUser });
  }

  public async logout() {
    const redirect = () => {
      localStorage.clear();
      window.location.replace('/login');
    };

    try {
      await http.post(ApiEndpoints.LogoutUrl);
      return redirect();
    } catch {
      return redirect();
    }
  }

  public isAuthenticated(): boolean {
    return !isEmpty(this.getCurrentUser());
  }

  /**
   * Current authenticated user object
   */
  private getCurrentUser(): AuthUser | null {
    try {
      const currentUser = localStorage.getItem(CURRENT_USER_STORAGE_KEY);
      return currentUser != null ? JSON.parse(currentUser) : null;
    } catch {
      return null;
    }
  }

  /**
   * Save current user to local storage
   */
  private setCurrentUser(currentUser: { data: AuthUser }): Promise<AuthUser> {
    const { data } = currentUser;
    localStorage.setItem(CURRENT_USER_STORAGE_KEY, JSON.stringify(data));
    if (process.env.NODE_ENV === 'production') {
      Sentry.setUser({ id: data.id, fullName: data.name, username: data.name ?? data.id, email: data.email });
    }
    return Promise.resolve(data);
  }
}

export const authService = new AuthService();
