import { Box, Skeleton } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import { HideOnMobile } from '../Helpers/HideOnMobile';
import { LoaderContainer } from './LoaderContainer';
import * as React from 'react';

export const SpotlightLoader = React.memo(() => {
  return (
    <LoaderContainer>
      <Box display="flex" flexDirection="row" bg="white" width="100%" height="auto">
        <Box width={isMobile ? '100%' : '50%'} my={2} mx={2}>
          <Skeleton width="100%" height="12px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />

          <Skeleton width="100%" mt={6} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          <Skeleton width="100%" mt={2} height="14px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
        </Box>
        <HideOnMobile>
          <Box width="50%" my={2} mx={2}>
            <Skeleton width="100%" height="20px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
            <Skeleton width="100%" mt={2} height="260px" rounded={8} startColor="#f3f3f3" endColor="#ecebeb" />
          </Box>
        </HideOnMobile>
      </Box>
    </LoaderContainer>
  );
});
