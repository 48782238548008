import { Box, Flex, SimpleGrid, VStack } from '@chakra-ui/react';
import { DashboardCounterLoader } from './DashboardCounterLoader.tsx';
import { DetailSingleContainer } from '../SplitView/DetailContainer.tsx';
import { DashboardHeaderLoader } from './DashboardHeaderLoader.tsx';
import { DashboardChartLoader } from './DashboardChartLoader.tsx';
import * as React from 'react';

export const DashboardLoadingState = React.memo(() => {
  return (
    <DetailSingleContainer>
      <Box mt="2rem" p={4}>
        <div className="container-limited">
          <VStack width="100%" align="stretch" spacing={8}>
            <Flex alignItems="center">
              <Box w="100%" bg="white" p={4}>
                <DashboardHeaderLoader />
              </Box>
            </Flex>
            <SimpleGrid minChildWidth="320px" spacing="14px">
              <DashboardCounterLoader />
              <DashboardCounterLoader />
              <DashboardCounterLoader />
              <DashboardCounterLoader />
              <DashboardCounterLoader />
              <DashboardCounterLoader />
            </SimpleGrid>
            <Box w="100%" h="100%" bg="transparent" textAlign="center">
              <Box bg="white">
                <DashboardChartLoader />
              </Box>
            </Box>
            <Box w="100%" h="100%" bg="transparent" textAlign="center">
              <Box bg="white">
                <DashboardChartLoader />
              </Box>
            </Box>
          </VStack>
        </div>
      </Box>
    </DetailSingleContainer>
  );
});
