import { Box, IconButton } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as React from 'react';
import { useIsOnMobile } from '../../hooks/useIsOnMobile';
import styles from './Toolbar.module.scss';

interface ToolbarProps {
  position?: 'top' | 'bottom';
  disabled?: boolean;
  usedInCreator?: boolean;
  onGoBack?: () => void;
}

export const Toolbar: React.FC<React.PropsWithChildren<ToolbarProps>> = ({
  position = 'top',
  usedInCreator = false,
  disabled,
  children,
  onGoBack,
}) => {
  const isOnMobile = useIsOnMobile();
  const isOnTop = position === 'top';
  const isOnBottom = position === 'bottom';

  const barPosition = clsx({
    [styles.top]: isOnTop,
    [styles.bottom]: isOnBottom,
  });

  const classes = clsx(styles.toolbar, { [styles.isMobile]: isOnMobile }, 'p-2 d-print-none ps-3 pe-3', barPosition, {
    [styles.disabled]: disabled,
  });

  return (
    <Box className={classes}>
      {isOnMobile ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box>
            {isOnTop ? (
              <IconButton
                onClick={onGoBack}
                color="gray.600"
                variant="ghost"
                size="md"
                aria-label="Go Back"
                fontSize="24px"
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            ) : null}
          </Box>
          <Box>{children}</Box>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={usedInCreator ? 'flex-end' : 'space-between'}
          width="100%"
          gap="1rem"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};
