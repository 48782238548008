import { Flex, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import * as React from 'react';
import { isMobile } from 'react-device-detect';

export const NavbarItemLoader = React.memo(() => {
  return (
    <Flex direction="row" alignItems="center" width={isMobile ? '50px' : '160px'} height="50px">
      <SkeletonCircle startColor="#f3f3f3" endColor="#ecebeb" size={isMobile ? '30' : '10'} />
      {isMobile ? null : (
        <SkeletonText startColor="#f3f3f3" endColor="#ecebeb" width="100px" noOfLines={2} spacing={2} ml={2} />
      )}
    </Flex>
  );
});
