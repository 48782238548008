import { Box, ButtonGroup, Flex, Skeleton, Stack } from '@chakra-ui/react';
import {
  faCog,
  faEnvelope,
  faFilePdf,
  faPenToSquare,
  faSquareCheck,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useMatch } from 'react-router-dom';
import { ThemeSwitcherMenu } from '../../../Treasuries/components/ThemeSwitcherMenu.tsx';
import { Toolbar } from '../Toolbar/Toolbar';
import { ToolbarButton } from '../Toolbar/ToolbarButton';
import { LoaderContainer } from './LoaderContainer';

export const InvoiceLoadingState = React.memo(() => {
  const matchesInvoices = useMatch('/invoices/*');
  const matchesEstimates = useMatch('/estimates/*');

  return (
    <Box height="100%" width="100%">
      <Box opacity={0.8} height="auto">
        <Toolbar disabled>
          <>
            <Box display="flex" justifyContent="flex-start">
              <ButtonGroup spacing={3}>
                {matchesInvoices ? (
                  <ToolbarButton buttonType="icon" label="Наплати" icon={<FontAwesomeIcon icon={faSquareCheck} />} />
                ) : null}
                <ToolbarButton buttonType="icon" label="Измени" icon={<FontAwesomeIcon icon={faPenToSquare} />} />
                <ToolbarButton buttonType="icon" label="Додади во корпа" icon={<FontAwesomeIcon icon={faTrashCan} />} />
              </ButtonGroup>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <ButtonGroup spacing={3} display="flex" alignItems="center">
                {matchesInvoices || matchesEstimates ? (
                  <ToolbarButton
                    colorScheme="gray"
                    buttonType="button"
                    label="Испрати по емаил"
                    icon={<FontAwesomeIcon icon={faEnvelope} size="lg" />}
                  >
                    Испрати по емаил
                  </ToolbarButton>
                ) : null}
                <ToolbarButton
                  colorScheme="gray"
                  buttonType="button"
                  label="Превземи PDF"
                  icon={<FontAwesomeIcon icon={faFilePdf} size="lg" />}
                >
                  Превземи PDF
                </ToolbarButton>
                {matchesInvoices || matchesEstimates ? <ThemeSwitcherMenu /> : null}
                {matchesInvoices || matchesEstimates ? (
                  <ToolbarButton
                    placement="left"
                    buttonType="icon"
                    label="Подесувања"
                    icon={<FontAwesomeIcon icon={faCog} />}
                  />
                ) : null}
              </ButtonGroup>
            </Box>
          </>
        </Toolbar>
      </Box>
      <LoaderContainer style={{ background: '#F7F8FB' }}>
        <Box
          margin="0 auto"
          marginTop="3.5rem"
          minHeight="1024px"
          width="100%"
          height="100%"
          backgroundColor="white"
          borderRadius="3px"
        >
          <Box
            p={4}
            width="100%"
            height="100%"
            minHeight="1024px"
            display="flex"
            flexDir="column"
            justifyContent="space-between"
          >
            <Flex direction="row" justifyContent="space-between" alignItems="center">
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100px" height="80px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="320px" height="80px" />
            </Flex>

            <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="60px" mt={10} />

            <Flex mt={10} direction="row" justifyContent="space-between" alignItems="center">
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="200px" height="40px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="200px" height="40px" />
            </Flex>

            <Stack spacing={4} mt={10}>
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="16px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="16px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="16px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="16px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="100%" height="16px" />
            </Stack>
            <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="360px" height="20px" mt={10} />
            <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="360px" height="20px" mt={2} />

            <Flex justifyContent="space-between" mt={16}>
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="220px" height="14px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="220px" height="14px" />
              <Skeleton startColor="#f3f3f3" endColor="#ecebeb" width="220px" height="14px" />
            </Flex>
          </Box>
        </Box>
      </LoaderContainer>
    </Box>
  );
});
