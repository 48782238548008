import { Box, Text } from '@chakra-ui/react';
import clsx from 'clsx';
import isEmpty from 'lodash-es/isEmpty';
import * as React from 'react';
import { SpotlightEntity } from '../api/spotlight.types';

interface Props {
  title: string;
  items?: SpotlightEntity[];
  selected: SpotlightEntity | null;
  setSelected?: (item: SpotlightEntity) => SpotlightEntity | unknown;
  onClick?: (item: SpotlightEntity) => SpotlightEntity | unknown;
  children?: (item: SpotlightEntity) => React.ReactElement | null;
}

export const SpotlightMasterList: React.FC<Props> = ({ title, items, children, selected, setSelected, onClick }) => {
  return items ? (
    <>
      <Box>
        {!isEmpty(items) ? (
          <Box className="entry-group mt-2">
            <Text fontWeight="semibold">{title}</Text>
          </Box>
        ) : null}
        <Box as="ul" display="flex" flexDirection="column" className="list-group list-group-flush">
          {items?.map((item) => (
            <li
              key={item.id}
              onClick={() => onClick?.(item)}
              onMouseEnter={() => setSelected?.(item)}
              className={clsx('list-group-item d-flex align-items-center ps-1 p-2', {
                active: selected ? item.id === selected.id : false,
              })}
            >
              {children ? children(item) : null}
            </li>
          ))}
        </Box>
      </Box>
    </>
  ) : null;
};

SpotlightMasterList.displayName = 'SpotlightMasterList';
