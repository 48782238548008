import { useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import { useAppSelector } from '../AppStore';

export function useSidebarCollapsed(isSmall?: boolean): boolean {
  const isCollapsed = useAppSelector(({ ui }) => ui.sidebar.isCollapsed);
  const [, setCollapsed] = useLocalStorage<boolean>('brojki:sidebarCollapsed', isCollapsed);

  useEffect(() => {
    setCollapsed(isCollapsed);
  }, [isCollapsed, setCollapsed]);

  return isSmall ? true : (isCollapsed ?? false);
}
