import { Estimate } from '../../Estimates/api/estimates.types';
import { Invoice } from '../../Invoices/api/invoices.types';

export type Treasury = Invoice | Estimate;

export enum TreasuryTheme {
  Modern = 'Modern',
  Classic = 'Classic',
  Simple = 'Simple',
}

export enum TreasuryType {
  Invoice = 'Invoice',
  Estimate = 'Estimate',
  Advance = 'Advance',
  ProForma = 'ProForma',
  Quote = 'Quote',
  Offer = 'Offer',
  Proposal = 'Proposal',
  Recurring = 'Recurring',
  Commercial = 'Commercial',
  Progress = 'Progress',
  Interim = 'Interim',
  Final = 'Final',
  PastDue = 'PastDue',
  Automatic = 'Automatic',
  CreditMemo = 'CreditMemo',
  DebitMemo = 'DebitMemo',
  TimeSheet = 'TimeSheet',
  Miscellaneous = 'Miscellaneous',
}

export const estimateTypes = ['Estimate', 'ProForma', 'Offer', 'Quote', 'Proposal'];
export const invoiceTypes = ['Invoice', 'Advance'];

export const getTreasuryType = (treasury: Treasury): string => {
  const types: Record<TreasuryType, string> = {
    [TreasuryType.Invoice]: 'Фактура',
    [TreasuryType.Advance]: 'Авансна',
    [TreasuryType.Estimate]: 'Про Фактура',
    [TreasuryType.ProForma]: 'Про Фактура',
    [TreasuryType.Quote]: 'Понуда',
    [TreasuryType.Offer]: 'Понуда',
    [TreasuryType.Proposal]: 'Понуда',
    [TreasuryType.Recurring]: 'Повторувачки',
    [TreasuryType.Commercial]: 'Комерцијална',
    [TreasuryType.Progress]: 'Во тек',
    [TreasuryType.Interim]: 'Привремена',
    [TreasuryType.Final]: 'Конечен',
    [TreasuryType.PastDue]: 'PastDue',
    [TreasuryType.Automatic]: 'Автоматски',
    [TreasuryType.CreditMemo]: 'Кредитен меморандум',
    [TreasuryType.DebitMemo]: 'Дебитен меморандум',
    [TreasuryType.TimeSheet]: 'Временски лист',
    [TreasuryType.Miscellaneous]: 'Разно',
  };

  return types[treasury.type];
};

export enum PaymentStatus {
  Paid = 'Paid',
  PartiallyPaid = 'PartiallyPaid',
  Unpaid = 'Unpaid', // WTF is this?
  Overdue = 'Overdue',
  Denied = 'Denied',
  Failed = 'Failed',
  Pending = 'Pending',
}

export const isInvoice = (treasury: Treasury): treasury is Invoice => treasury.type === TreasuryType.Invoice;
export const isEstimate = (treasury: Treasury): treasury is Estimate => estimateTypes.includes(treasury.type);
