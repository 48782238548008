import { Box, Skeleton } from '@chakra-ui/react';
import * as React from 'react';
import { LoaderContainer } from './LoaderContainer';

export const DashboardCounterLoader = React.memo(() => {
  return (
    <LoaderContainer>
      <Box padding="2">
        <Skeleton height="54px" width="54px" startColor="#f3f3f3" endColor="#ecebeb" />
      </Box>
    </LoaderContainer>
  );
});
