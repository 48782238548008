export enum PaymentMethod {
  Cash = 'Cash',
  Invoice = 'Invoice',
  BankTransfer = 'BankTransfer',
  Cheque = 'Cheque',
  CreditCard = 'CreditCard',
  DebitCard = 'DebitCard',
  PayPal = 'PayPal',
  MasterCard = 'MasterCard',
  Visa = 'Visa',
  DinersClub = 'DinersClub',
  AMEX = 'AMEX',
}

export const paymentMethods = [
  {
    value: PaymentMethod.Cash,
    name: 'Кеш',
  },
  {
    value: PaymentMethod.Invoice,
    name: 'Фактура',
  },
  {
    value: PaymentMethod.BankTransfer,
    name: 'Банкарски трансфер',
  },
  {
    value: PaymentMethod.Cheque,
    name: 'Чек',
  },
  {
    value: PaymentMethod.CreditCard,
    name: 'Кредитна Картичка',
  },
  {
    value: PaymentMethod.DebitCard,
    name: 'Дебитна Картичка',
  },
  {
    value: PaymentMethod.PayPal,
    name: 'PayPal',
  },
  {
    value: PaymentMethod.MasterCard,
    name: 'Master Card',
  },
  {
    value: PaymentMethod.Visa,
    name: 'Visa',
  },
  {
    value: PaymentMethod.DinersClub,
    name: 'Diners Club',
  },
  {
    value: PaymentMethod.AMEX,
    name: 'AMEX',
  },
];
